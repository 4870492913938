import React from "react";
import styles from "./MyFirstMenu.module.css";
import arrForHP from "./arrayHomePage.js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


const MyFirstMenu = ({sideMenuItems, setMenu, setDrDo, handleItemHover, forHeadCon }) => {
       // Function to render content based on the active state
       const renderContent = () => {
        if (forHeadCon.autoOff) {
            return (
                <div className={styles.ForFlexColumn}>
                    <Link to="/circuitBreakers/poles/3p/ЕТІ" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Триполюсні Hager</Link>
                    <Link  to="/circuitBreakers/poles/3p/ENEXT" onClick={()=>setDrDo(false)}  className={styles.itemsColumn}>Триполюсні E.NEXT</Link>
                    <Link  to="/circuitBreakers/capacity/4.5kA/ENEXT" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>4.5kA  E.NEXT</Link>
                    <Link to="/circuitBreakers/poles/2p/IEK"  onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Двополюсні IEK</Link>
                    <Link to="/circuitBreakers/poles/3p/IEK" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Триполюсні IEK</Link>
                    <Link to="/circuitBreakers/current/4.5kA/IEK" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>4.5kA IEK</Link>            
                </div>
            );
        } else if (forHeadCon.dyfAuto) {
            return (
                <div className={styles.ForFlexColumn}>
                    <Link  to="/difautomates/current/10А/ETI" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Струм 10A ETI</Link>
                    <Link to="/difautomates/current/16А/ETI" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Струм 16A ETI</Link>
                    <Link to="/difautomates/current/25А/Eaton" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Струм 25A Eaton</Link>      
                    <Link to="/difautomates/current/32А/Eaton" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Струм 32A Eaton</Link>
                    <Link to="/difautomates/current/40A/Schneider" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Струм 40A Schneider</Link>
                    <Link to="/difautomates/current/16А/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Струм 16A Hager</Link>
                </div>
            );
        }        
        else if (forHeadCon.pzb) {
            return (
                <div className={styles.ForFlexColumn}>
                    <Link to="/pzv/poles/2P/Eaton" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Двополюсний Eaton</Link>
                    <Link to="/pzv/poles/4P/Eaton" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Чотирьохполюсний Eaton</Link>
                    <Link to="/pzv/current/25A/Eaton" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Ток 25А Eaton</Link>
                    <Link  to="/pzv/current/25A/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Ток 25А Hager</Link>
                    <Link  to="/pzv/current/40A/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Ток 40А Hager</Link>
                    <Link to="/pzv/current/63A/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Ток 63А Hager</Link>
                </div>
            );
        }
        else if (forHeadCon.contactors) {
            return (
                <div className={styles.ForFlexColumn}>
                    <Link  to="/contactors/current/25A/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>25A Hager</Link>
                    <Link  to="/contactors/current/40A/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>40A Hager</Link>
                    <Link to="/contactors/current/63A/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>63A Hager</Link>
                    <Link to="/contactors/current/18A/ENEXT" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>18A E.NEXT</Link>
                    <Link to="/contactors/current/25A/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>25A E.NEXT</Link>
                    <Link to="/contactors/current/40A/ENEXT" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>40A E.NEXT</Link>
                </div>
            );
        }
        else if (forHeadCon.hagerSwitches) {
            return (
                <div className={styles.ForFlexColumn}>
                    <Link  to="/switchers/poles/1P/ETI" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Однополюсний ETI</Link>
                    <Link  to="/switchers/poles/2P/ETI" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Двополюсний ETI</Link>
                    <Link  to="/switchers/poles/3P/ETI" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Трьохполюсний ETI</Link>
                    <Link to="/switchers/poles/1P/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Однополюсний Hager</Link>
                    <Link  to="/switchers/poles/2P/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Двополюсний Hager</Link>
                    <Link to="/switchers/poles/3P/Hager" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Трьохполюсний Hager</Link>
                </div>
            );
        }
        else if (forHeadCon.distributionBoards) {
            return (
                <div className={styles.ForFlexColumn}>
                    <Link  to="/shields/modules/2/ENEXT" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>2-модульний E.NEXT</Link>
                    <Link  to="/shields/modules/4/ENEXT" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>4-модульний E.NEXT</Link>
                    <Link  to="/shields/modules/9/АСКО" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>9-модульний АСКО</Link>
                    <Link  to="/shields/modules/12/АСКО" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>12-модульний АСКО</Link>
                    <Link  to="/shields/modules/12/ETI" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>12-модульний ETI</Link>
                    <Link  to="/shields/modules/24/Mutlusan" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>24-модульний Mutlusan</Link>
                </div>
            );
        }
        else if (forHeadCon.lampsProjectors) {
            return (
                <div className={styles.ForFlexColumn}>
                    <Link  to="/lamps/capacity/18 W/DeLux" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Потужність 18W DeLux</Link>  
                    <Link  to="/lamps/capacity/20 W/DeLux" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Потужність 20W DeLux</Link>    
                    <Link to="/lamps/lumen/4100K/DeLux" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>4100K люменів DeLux</Link>
                    <Link  to="/lamps/capacity/18 W/Євросвітло" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Потужність 18W Євросвітло</Link>
                    <Link  to="/lamps/lumen/4200K/Євросвітло" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>4000K люменів Євросвітло</Link>
                    <Link to="/lamps/lumen/4200K/Євросвітло" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>4200K люменів Євросвітло</Link>
                </div>
            );
        }
        else if (forHeadCon.cableWire) {
            return (
                <div className={styles.ForFlexColumn}>
                    <Link  to="/cables/cores/5/ГАЛ-КАТ" onClick={()=>setDrDo(false)}  className={styles.itemsColumn}>П'ятижильний ГАЛ-КАТ</Link>
                    <Link to="/cables/section/1,5/ГАЛ-КАТ" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Переріз 1.5мм ГАЛ-КАТ</Link>
                    <Link to="/cables/cores/3/ЗЗКМ" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Трьохжильний ЗЗКМ</Link>
                    <Link to="/cables/cores/2/НІВІТ" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Двожильний НІВІТ</Link>
                    <Link to="/cables/section/2,5/ЕСК"  onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Переріз 2.5мм ЕСК</Link>
                    <Link to="/cables/section/1,5/Одескабель" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Переріз 1.5мм Одескабель</Link>
                </div>
            );
        } else if (forHeadCon.cableRoutingSystems) {
            return (
                <div className={styles.ForFlexColumn}>
                    <Link to="/ardicandscat/type/Підйом/Ardic" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Тип "Підйом" Ardic</Link>
                    <Link to="/ardicandscat/type/Поворот/Ardic" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Тип "Поворот" Ardic</Link> 
                    <Link to="/ardicandscat/dimensions/50х50мм/Ardic" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Розмір 50х50мм Ardic</Link> 
                    <Link to="/ardicandscat/dimensions/100х50мм/Ardic" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Розмір 100х50мм Ardic</Link>
                    <Link to="/ardicandscat/type/Кришка/SCaT" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Тип "Кришка" SCaT</Link>
                    <Link to="/ardicandscat/type/Лоток/SCaT" onClick={()=>setDrDo(false)} className={styles.itemsColumn}>Тип "Лоток" SCaT</Link>
                </div>
            );
        }
    }; 
    return(
        <div id={styles.MenuElement}>
            <div onClick={()=>setMenu(false)} className={styles.topBackButton} ><FontAwesomeIcon icon={faChevronLeft} /><span className={styles.texttopButton}>Категорії</span></div>
            {renderContent()}
        </div>
    );
};

export default MyFirstMenu;