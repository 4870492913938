import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AVR = ({setPCart}) => {
    return (
        <div id={styles.contentHeadDrDo}>
            <Link onClick={()=>setPCart(false)} to="avrs" className={styles.subMenuItem}>АВР</Link>                
        </div>
    );
};

export default AVR;