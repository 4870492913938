import React, { useState } from "react";
import styles from "./DropDown.module.css";
import AutoOff from "./DropDownContent/AvtoOff.js";
import DyfAuto from "./DropDownContent/DyfAuto.js";
import Pzb from "./DropDownContent/Pzb.js";
import Contactors from "./DropDownContent/Contactors.js";
import HagerSwitches from "./DropDownContent/HagerSwitches.js";
import RelaysControllersAVR from "./DropDownContent/RelaysControllersAVR.js";
import Thermoregulators from "./DropDownContent/Thermoregulators.js";
import DistributionBoards from "./DropDownContent/DistributionBoards.js";
import LampsProjectors from "./DropDownContent/LampsProjectors.js";
import SocketsSwitches from "./DropDownContent/SocketsSwitches.js";
import PowerConnectors from "./DropDownContent/PowerConnectors.js";
import CableWire from "./DropDownContent/CableWire.js";
import CableRoutingSystems from "./DropDownContent/CableRoutingSystems.js";
import AVR from "./DropDownContent/Avr.js";
import SipWireFittings from "./DropDownContent/SipWireFittings.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faEthernet } from '@fortawesome/free-solid-svg-icons';
import SubmenuMain from "./Submenu.js";
import { faShuffle, faScrewdriverWrench, faToggleOff, faCircleNodes, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { faPlug, faRoute, faPager, faRepeat, faTemperatureArrowDown, faClockRotateLeft, faPlusMinus, faShield} from '@fortawesome/free-solid-svg-icons';
import ContentFilters from "./ContentFilters.js";

const DropDown = ({ innerElementRef, setDrDo }) => {
    // State object for managing active sections
    const [forHeadCon, setForHeadCon] = useState({
        autoOff: false,
        dyfAuto: false,
        pzb: false,
        contactors: false,
        hagerSwitches: false,
        relaysControllersAVR: false,
        thermoregulators: false,
        distributionBoards: false,
        lampsProjectors: false,
        socketsSwitches: false,
        powerConnectors: false,
        cableWire: false,
        cableRoutingSystems: false,
        sipWireFittings: false,
        lightSignalFittings: false,
        AVR: false
    });
    const forHeadIcons = [
        <FontAwesomeIcon icon={faPowerOff} />,
        <FontAwesomeIcon icon={faPlusMinus} />,
        <FontAwesomeIcon icon={faShield} />,
        <FontAwesomeIcon icon={faEthernet} />,
        <FontAwesomeIcon icon={faToggleOff} />,
        <FontAwesomeIcon icon={faClockRotateLeft} />,
        <FontAwesomeIcon icon={faPager} />,
        <FontAwesomeIcon icon={faLightbulb} />,
        <FontAwesomeIcon icon={faPlug} />,
        <FontAwesomeIcon icon={faRoute} />,
        <FontAwesomeIcon icon={faCircleNodes} />,
        <FontAwesomeIcon icon={faScrewdriverWrench} />,
        <FontAwesomeIcon icon={faTemperatureArrowDown} />,
        <FontAwesomeIcon icon={faShuffle} />,
        <FontAwesomeIcon icon={faRepeat} />
    ];
    
    
    const sideMenuItems = [
        "Автоматичні вимикачі",
        "Диференційні автомати",
        "ПЗВ",
        "Контактори",
        "Рубильники та роз'єднувачі",
        "Щити розподільні",
        "Лампи, світильники, прожектори",
        "Кабель, провід",
        "Системи прокладки кабелю",
        "АВР",
    ];
    // Handler for hovering over a menu item
    const handleItemHover = (item) => {
        // Reset all properties to false
        const newState = Object.keys(forHeadCon).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {});

        // Set the hovered item to true
        switch (item) {
            case "Автоматичні вимикачі":
                newState.autoOff = true;
                console.log(forHeadCon);
                break;
                
            case "Диференційні автомати":
                newState.dyfAuto = true;
                break;
            // Add cases for each side menu item
            case "ПЗВ":
                newState.pzb = true;
                break;
            case "Контактори":
                newState.contactors = true;
                break;
            case "Рубильники та роз'єднувачі":
                newState.hagerSwitches = true;
                break;            
            case "Терморегулятори":
                newState.thermoregulators = true;
                break;
            case "Щити розподільні":
                newState.distributionBoards = true;
                break;
            case "Лампи, світильники, прожектори":
                newState.lampsProjectors = true;
                break;
            case "Розетки і вимикачі":
                newState.socketsSwitches = true;
                break;
            case "Кабель, провід":
                newState.cableWire = true;
                break;
            case "Системи прокладки кабелю":
                newState.cableRoutingSystems = true;
                break;
            case "АВР":
                newState.AVR = true;
                break;              
            case "Арматура для проводу СІП":
                newState.sipWireFittings = true;
                break;            
            default:
                break;
        }
        setForHeadCon(newState);
    };
    
    // Function to render content based on the active state
    const renderContent = () => {
        if (forHeadCon.autoOff) {
            return <AutoOff />;
        } else if (forHeadCon.dyfAuto) {
            return <DyfAuto />;
        } else if (forHeadCon.pzb) {
            return <Pzb />;
        } else if (forHeadCon.contactors) {
            return <Contactors />;
        } else if (forHeadCon.hagerSwitches) {
            return <HagerSwitches />;
        } else if (forHeadCon.relaysControllersAVR) {
            return <RelaysControllersAVR />;
        } else if (forHeadCon.thermoregulators) {
            return <Thermoregulators />;
        } else if (forHeadCon.distributionBoards) {
            return <DistributionBoards />;
        } else if (forHeadCon.lampsProjectors) {
            return <LampsProjectors />;
        } else if (forHeadCon.socketsSwitches) {
            return <SocketsSwitches />;
        } else if (forHeadCon.powerConnectors) {
            return <PowerConnectors />;
        } else if (forHeadCon.cableWire) {
            return <CableWire />;
        } else if (forHeadCon.cableRoutingSystems) {
            return <CableRoutingSystems />;
        } else if (forHeadCon.sipWireFittings) {
            return <SipWireFittings />;
        } else if (forHeadCon.AVR) {
            return <AVR />;
        } else {
            return <div>Please hover over a side menu item.</div>;
        }
    };

    return (        
        <div id={styles.wholePage}>
            
            <div id={styles.forHeadDropDown} ref={innerElementRef}>
                <SubmenuMain setDrDo={setDrDo} handleItemHover={handleItemHover} forHeadIcons={forHeadIcons} forHeadCon={forHeadCon} sideMenuItems={sideMenuItems} />
                <div id={styles.contentHeadDrDo}>
                    {renderContent()}
                </div>
            </div>
        </div>        
    );
};

export default DropDown;
