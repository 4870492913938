import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
    return (
        <div id={styles.forFooter}>
                    
        </div>
    );
};
export default Footer;