import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = ({setPCart}) => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>E. NEXT</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/2/ENEXT" className={styles.subMenuItem}>2-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/4/ENEXT" className={styles.subMenuItem}>4-модульний</Link>     
                <Link onClick={()=>setPCart(false)} to="/shields/modules/6/ENEXT" className={styles.subMenuItem}>6-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/8/ENEXT" className={styles.subMenuItem}>8-модульний</Link> 
                <Link onClick={()=>setPCart(false)} to="/shields/modules/12/ENEXT" className={styles.subMenuItem}>12-модульний</Link> 
                <Link onClick={()=>setPCart(false)} to="/shields/modules/24/ENEXT" className={styles.subMenuItem}>24-модульний</Link>
            </div>            
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>АСКО</h3></Link>                 
                <Link onClick={()=>setPCart(false)} to="/shields/modules/6/АСКО" className={styles.subMenuItem}>6-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/9/АСКО" className={styles.subMenuItem}>9-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/12/АСКО" className={styles.subMenuItem}>12-модульний</Link> 
                <Link onClick={()=>setPCart(false)} to="/shields/modules/24/АСКО" className={styles.subMenuItem}>24-модульний</Link>     
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ЕТІ</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/4/ETI" className={styles.subMenuItem}>4-модульний</Link>     
                <Link onClick={()=>setPCart(false)} to="/shields/modules/6/ETI" className={styles.subMenuItem}>6-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/8/ETI" className={styles.subMenuItem}>8-модульний</Link> 
                <Link onClick={()=>setPCart(false)} to="/shields/modules/12/ETI" className={styles.subMenuItem}>12-модульний</Link> 
                <Link onClick={()=>setPCart(false)} to="/shields/modules/24/ETI" className={styles.subMenuItem}>24-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/36/ETI" className={styles.subMenuItem}>36-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/48/ETI" className={styles.subMenuItem}>48-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/56/ETI" className={styles.subMenuItem}>56-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/70/ETI" className={styles.subMenuItem}>70-модульний</Link>
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Mutlusan</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/4/Mutlusan" className={styles.subMenuItem}>4-модульний</Link>     
                <Link onClick={()=>setPCart(false)} to="/shields/modules/6/Mutlusan" className={styles.subMenuItem}>6-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/9/Mutlusan" className={styles.subMenuItem}>9-модульний</Link> 
                <Link onClick={()=>setPCart(false)} to="/shields/modules/12/Mutlusan" className={styles.subMenuItem}>12-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/18/Mutlusan" className={styles.subMenuItem}>18-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/24/Mutlusan" className={styles.subMenuItem}>24-модульний</Link>
                <Link onClick={()=>setPCart(false)} to="/shields/modules/36/Mutlusan" className={styles.subMenuItem}>36-модульний</Link>           
            </div>
        </div>
    );
};

export default AutoOff;