import React from "react";
import styles from "./Main.module.css";
import Header from "./Header.js";
import MainContent3 from "./MainContent3.js";
import Footer from "./Footer.js";

const CheckOut = () => {
    return(
        <div id={styles.wrapper}>            
            <div id={styles.faq}></div>
            <Header />
            <MainContent3 />
            <Footer />            
        </div>
    );
};

export default CheckOut;