import React, { useState } from "react";
import styles from "./Maincontent.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faEthernet , faShuffle, faPowerOff,faPlusMinus, faShield, faToggleOff,faClockRotateLeft, faPager    } from '@fortawesome/free-solid-svg-icons';
import { faCircleNodes, faScrewdriverWrench, faTemperatureArrowDown, faRepeat } from '@fortawesome/free-solid-svg-icons';
import { faPlug, faRoute} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";


const SubMenuElement = () => {
    // State to keep track of the clicked element and whether to add a new element
const [clickedElementIndex, setClickedElementIndex] = useState(null);
// Side menu items array
const sideMenuItemsSubCon = [
    "Автоматичні вимикачі",
    "Диференційні автомати",
    "ПЗВ",
    "Контактори",
    "Рубильники та роз'єднувачі",
    "Таймери, реле",
    "Щити розподільні",
    "Лампи, світильники, прожектори",
    "Розетки і вимикачі",
    "Кабель, провід",
    "Системи прокладки кабелю",
    "Мателорукава",        
    "Терморегулятори",
    "Гофра, тримачі для гофри",        
    "Реле напруги",
    "АВР"        
];
const forHeadIcons = [
    <FontAwesomeIcon icon={faPowerOff} />,
    <FontAwesomeIcon icon={faPlusMinus} />,
    <FontAwesomeIcon icon={faShield} />,
    <FontAwesomeIcon icon={faEthernet} />,
    <FontAwesomeIcon icon={faToggleOff} />,
    <FontAwesomeIcon icon={faClockRotateLeft} />,
    <FontAwesomeIcon icon={faPager} />,
    <FontAwesomeIcon icon={faLightbulb} />,
    <FontAwesomeIcon icon={faPlug} />,
    <FontAwesomeIcon icon={faRoute} />,
    <FontAwesomeIcon icon={faCircleNodes} />,
    <FontAwesomeIcon icon={faScrewdriverWrench} />,
    <FontAwesomeIcon icon={faTemperatureArrowDown} />,
    <FontAwesomeIcon icon={faShuffle} />,
    <FontAwesomeIcon icon={faRepeat} />,
    <FontAwesomeIcon icon={faPowerOff} />
];
const [hoe, forHoe] = useState('');
    // Click event handler
    const handleClick = (index) => {
        setClickedElementIndex((state) => {
            if (index !== state) {
                // Return the new index if the current state is different from the provided index
                return index;
            } else {
                // Return null if the current state is the same as the provided index
                return null;
            }
        });
        

    // Check the clicked index and update the `hoe` state accordingly
    if (index === 0) {
        forHoe('content0'); // Set `hoe` state to a specific value for index 0
    } else if (index === 1) {
        forHoe('content1'); // Set `hoe` state to a different value for index 1
    } else if (index === 2) {
        forHoe('content2'); // Set `hoe` state to a different value for index 1
    } else if (index === 3) {
        forHoe('content3'); // Set `hoe` state to a different value for index 1
    } else if (index === 4) {
        forHoe('content4'); // Set `hoe` state to a different value for index 1
    } else if (index === 5) {
        forHoe('content5'); // Set `hoe` state to a different value for index 1
    } else if (index === 6) {
        forHoe('content6'); // Set `hoe` state to a different value for index 1
    } else if (index === 7) {
        forHoe('content7'); // Set `hoe` state to a different value for index 1
    } else if (index === 8) {
        forHoe('content8'); // Set `hoe` state to a different value for index 1
    } else if (index === 9) {
        forHoe('content9'); // Set `hoe` state to a different value for index 1
    } else if (index === 10) {
        forHoe('content10'); // Set `hoe` state to a different value for index 1
    } else if (index === 11) {
        forHoe('content11'); // Set `hoe` state to a different value for index 1
    } else if (index === 12) {
        forHoe('content12'); // Set `hoe` state to a different value for index 1
    } else if (index === 13) {
        forHoe('content13'); // Set `hoe` state to a different value for index 1
    } else if (index === 14) {
        forHoe('content14'); // Set `hoe` state to a different value for index 1
    } else if (index === 15) {
        forHoe('content15'); // Set `hoe` state to a different value for index 1
    } else {
        forHoe(''); // Reset `hoe` state for other indexes
    }
};

// Function to render content based on the value of `hoe` state
const renderContent = () => {
    if (hoe === 'content0') {
        return (
            <>
                <Link to="/circuitBreakers/Hager" className={styles.MyPs}>Hager</Link>
                <Link to="/circuitBreakers/ЕТІ" className={styles.MyPs}>ETI</Link>
                <Link to="/circuitBreakers/EATON" className={styles.MyPs}>EATON</Link>
                <Link to="/circuitBreakers/ENEXT" className={styles.MyPs}>E.NEXT</Link>
                <Link to="/circuitBreakers/IEK" className={styles.MyPs}>IEK</Link>
                <Link to="/circuitBreakers/Schneider" className={styles.MyPs}>Schneider</Link>
            </>
        );
    } else if (hoe === 'content1') {
        return (
            <>
                <Link to="/difautomates/Hager" className={styles.MyPs}>Hager</Link>
                <Link to="/difautomates/ETI" className={styles.MyPs}>ETI</Link>
                <Link to="/difautomates/EATON" className={styles.MyPs}>EATON</Link>
                <Link to="/difautomates/ENEXT" className={styles.MyPs}>E.NEXT</Link>
                <Link to="/difautomates/Schneider" className={styles.MyPs}>Schneider</Link>                    
            </>
        );
    } else if (hoe === 'content2') {
        return (
            <>
                <Link to="/pzv/Hager" className={styles.MyPs}>Hager</Link>
                <Link to="/pzv/EATON" className={styles.MyPs}>EATON</Link>                    
            </>
        );
    } else if (hoe === 'content3') {
        return (
            <>
                <Link to="/contactors/Hager" className={styles.MyPs}>Hager</Link>
                <Link to="/contactors/ЕТІ" className={styles.MyPs}>ETI</Link>
                <Link to="/contactors/EATON" className={styles.MyPs}>EATON</Link>
                <Link to="/contactors/IEK" className={styles.MyPs}>IEK</Link> 
                <Link to="/contactors/ENEXT" className={styles.MyPs}>E.NEXT</Link>
            </>
        );
    } else if (hoe === 'content4') {
        return (
            <>
                <Link to="/switchers/Hager" className={styles.MyPs}>Hager</Link>
                <Link to="/switchers/ETI" className={styles.MyPs}>ETI</Link>
            </>
        );
    } else if (hoe === 'content5') {
        return (
            <>
                <Link to="/timers/ЕНЕКСТ" className={styles.MyPs}>E.NEXT</Link>
                <Link to="/timers/ЕТІ" className={styles.MyPs}>ETI</Link>
                <Link to="/timers/Аско" className={styles.MyPs}>АСКО</Link>                
            </>
        );
    } else if (hoe === 'content6') {
        return (
            <>
                <Link to="/shields/ENEXT" className={styles.MyPs}>E.NEXT</Link>
                <Link to="/shields/Hager" className={styles.MyPs}>Hager</Link>
                <Link to="/shields/Mutlusan" className={styles.MyPs}>Mutlusan</Link>
                <Link to="/shields/ETI" className={styles.MyPs}>ETI</Link>
                <Link to="/shields/АСКО" className={styles.MyPs}>АСКО</Link>
            </>
        );
    } else if (hoe === 'content7') {
        return (
            <>
                <Link to="/lamps/Євросвітло " className={styles.MyPs}>Євросвітло</Link>
                <Link to="/lamps/DELUX" className={styles.MyPs}>Deluxe</Link>
            </>
        );
    } else if (hoe === 'content8') {
        return (
            <>
                <Link to="/sockets/Hager" className={styles.MyPs}>Hager</Link>
                <Link to="/sockets/Schneider" className={styles.MyPs}>Schneider</Link>                
            </>
        );
    } else if (hoe === 'content9') {
        return (
            <>
                <Link to="/cables/ADSS" className={styles.MyPs}>ADSS</Link>
                <Link to="/cables/F660" className={styles.MyPs}>Коаксіальник кабель</Link>
                <Link to="/cables/FTP" className={styles.MyPs}>FTP</Link>
                <Link to="/cables/UTP" className={styles.MyPs}>UTP</Link>
                <Link to="/cables/ВВГ" className={styles.MyPs}>Кабель силовий ВВГ</Link>
                <Link to="/cables/ВВГнг" className={styles.MyPs}>Кабель силовий ВВГнг</Link>
                <Link to="/cables/ВВГнгд" className={styles.MyPs}>Кабель силовий ВВГнгд</Link>
                <Link to="/cables/ПВ-1" className={styles.MyPs}>ПВ-1</Link>
                <Link to="/cables/ПВ-3" className={styles.MyPs}>ПВ-3</Link>
                <Link to="/cables/ШВВП" className={styles.MyPs}>Провід ШВВП</Link>
                <Link to="/cables/ВВГнг-LS" className={styles.MyPs}>ВВГнг-LS</Link>
                <Link to="/cables/ВВГнг-LS-П" className={styles.MyPs}>ВВГнг-LS-П</Link>
                <Link to="/cables/ВВГнг-П" className={styles.MyPs}>ВВГнг-П</Link>
                <Link to="/cables/ВВГп" className={styles.MyPs}>ВВГп</Link>
                <Link to="/cables/ПВС" className={styles.MyPs}>ПВС</Link>                
            </>
        );
    } else if (hoe === 'content10') {
        return (
            <>
                <Link to="/ardicandscat/Ardic" className={styles.MyPs}>Ardic</Link>
                <Link to="/ardicandscat/SCaT" className={styles.MyPs}>SCaT</Link>                                   
            </>
        );
    } else if (hoe === 'content11') {
        return (
            <>
                 <Link to="/metalhose/Україна" className={styles.MyPs}>Україна</Link>
                 <Link to="/metalhose/SCaT" className={styles.MyPs}>SCaT</Link>
                 <Link to="/metalhose/220тм" className={styles.MyPs}>220тм</Link>
                 <Link to="/metalhose/ТНСІ-TNSY" className={styles.MyPs}>ТНСІ-TNSY</Link>                                   
            </>
        );
    } else if (hoe === 'content12') {
        return (
            <>
                <Link to="/termoregulations/DigiTOP" className={styles.MyPs}>DigiTOP</Link>
                <Link to="/termocables/ZUBR" className={styles.MyPs}>Нагрівальний мат і кабель</Link>                                                        
            </>
        );
    } else if (hoe === 'content13') {
        return (
            <>
                <Link to="/tubes/КОПОС" className={styles.MyPs}>КОПОС</Link>
                <Link to="/tubes/ДКС" className={styles.MyPs}>ДКС</Link> 
                <Link to="/tubes/E.NEXT" className={styles.MyPs}>E.NEXT</Link>
                <Link to="/tubes/SOKOL" className={styles.MyPs}>SOKOL</Link>
                <Link to="/tubes/220тм" className={styles.MyPs}>220тм</Link>                                                                               
            </>
        );
    } else if (hoe === 'content14') {
        return (
            <>
                <Link to="/voltRelay/DigiTOP" className={styles.MyPs}>DigiTOP</Link>
                <Link to="/voltRelay/ZUBR" className={styles.MyPs}>ZUBR</Link>
                <Link to="/voltRelay/Новатек" className={styles.MyPs}>Новатек</Link>                                                                            
            </>
        );
    } else if (hoe === 'content15') {
        return (
            <>
                <Link to="/avrs" className={styles.subMenuItem}>АВР</Link>                                                                            
            </>
        );
    } else {
        return null;
    }
};

    return(
        <div id={styles.subConMenu}>
            {sideMenuItemsSubCon.map((item, index) => {
                const statsIcons = forHeadIcons[index]; // Declare inside function body
                
                return (
                    <React.Fragment key={index}>
                        <div
                            className={styles.sideMenuItemSubCon}
                            onClick={() => handleClick(index)}
                        >
                            <p className={styles.subMenuIcons}>{statsIcons}</p>
                            <p className={styles.subMenuItems}>{item}</p>                            
                        </div>
                        {/* Render a new element next to the clicked element */}
                        {clickedElementIndex === index && (
                            
                            <div className={styles.subSubMenu}>
                                {renderContent()}
                            </div>
                            
                        )}
                    </React.Fragment>
                );
            })}
            </div>
    );
};

export default SubMenuElement;



