import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = ({setDrDo}) => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ETI</h3></Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/1p/ETI" className={styles.subMenuItem}>Однополюсні</Link>
                <Link onClick={()=>setDrDo(false)}  to="/circuitBreakers/poles/2p/ЕТІ" className={styles.subMenuItem}>Двополюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/3p/ЕТІ"  className={styles.subMenuItem}>Триполюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/type/C/ЕТІ" className={styles.subMenuItem}>Тип С</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/type/D/ЕТІ" className={styles.subMenuItem}>Тип D</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/capacity/4.5kA/ЕТІ" className={styles.subMenuItem}>4.5kA</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/capacity/6kA/ЕТІ" className={styles.subMenuItem}>6kA</Link>
                <Link className={styles.subMenuItemHead}><h3>Eaton</h3></Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/1p/EATON" className={styles.subMenuItem}>Однополюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/2p/EATON" className={styles.subMenuItem}>Двополюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/3p/EATON" className={styles.subMenuItem}>Триполюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/type/C/EATON" className={styles.subMenuItem}>Тип С</Link>                        
            </div>                        
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>E. NEXT</h3></Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/1p/ENEXT" className={styles.subMenuItem}>Однополюсні</Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/2p/ENEXT" className={styles.subMenuItem}>Двополюсні</Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/3p/ENEXT" className={styles.subMenuItem}>Триполюсні</Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/capacity/4.5kA/ENEXT" className={styles.subMenuItem}>4.5kA</Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/capacity/6kA/ENEXT" className={styles.subMenuItem}>6kA</Link>                
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ІЕК</h3></Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/1p/IEK" className={styles.subMenuItem}>Однополюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/2p/IEK" className={styles.subMenuItem}>Двополюсні</Link>
                <Link onClick={()=>setDrDo(false)}to="/circuitBreakers/poles/3p/IEK" className={styles.subMenuItem}>Триполюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/current/4.5kA/IEK" className={styles.subMenuItem}>4.5kA</Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/current/10kA/IEK" className={styles.subMenuItem}>10kA</Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/current/35kA/IEK" className={styles.subMenuItem}>35kA</Link>                
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Schneider</h3></Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/1p/Schneider" className={styles.subMenuItem}>Однополюсні</Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/2p/Schneider" className={styles.subMenuItem}>Двополюсні</Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/3p/Schneider" className={styles.subMenuItem}>Триполюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/4p/Schneider" className={styles.subMenuItem}>Чотирьохполюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/type/C/Schneider" className={styles.subMenuItem}>Тип С</Link>
                <Link  onClick={()=>setDrDo(false)} to="/circuitBreakers/type/B/Schneider" className={styles.subMenuItem}>Тип B</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/capacity/6kA/Schneider" className={styles.subMenuItem}>6kA</Link>  
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Hager</h3></Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/1p/Hager" className={styles.subMenuItem}>Однополюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/2p/Hager" className={styles.subMenuItem}>Двополюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/3p/Hager" className={styles.subMenuItem}>Триполюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/poles/4p/Hager" className={styles.subMenuItem}>Чотирьохполюсні</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/type/C/Hager" className={styles.subMenuItem}>Тип С</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/type/D/Hager" className={styles.subMenuItem}>Тип D</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/capacity/6kA/Hager" className={styles.subMenuItem}>6kA</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/capacity/10kA/Hager" className={styles.subMenuItem}>10kA</Link>
                <Link onClick={()=>setDrDo(false)} to="/circuitBreakers/capacity/18kA/Hager" className={styles.subMenuItem}>18kA</Link>
            </div>
        </div>
    );
};

export default AutoOff;