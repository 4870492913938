import React from "react";

const HomePageImages = {
    circuitBreakers: require("./circuitBreakers.jpg"),
    differentialCircuitBreakers: require("./differentialCircuitBreakers.jpg"),
    residualCurrentDevices: require("./residualCurrentDevices.jpg"),
    contactors: require("./contactors.jpg"),
    switchDisconnectorsAndDisconnectors: require("./switchDisconnectorsAndDisconnectors.jpg"),
    timersRelays: require("./timersRelays.jpg"),
    distributionPanels: require("./distributionPanels.jpg"),
    lampsLightFixturesSpotlights: require("./lampsLightFixturesSpotlights.jpg"),
    socketsAndSwitches: require("./socketsAndSwitches.jpg"),
    cableWire: require("./cableWire.jpg"),
    cableManagementSystems: require("./cableManagementSystems.jpg"),
    hardwareForSIP: require("./hardwareForSIP.jpg"),
    thermostats: require("./thermostats.jpg"),
    automaticTransferSwitches: require("./automaticTransferSwitches.jpg"),
    voltageRelays: require("./voltageRelays.jpg")
};

export default HomePageImages;