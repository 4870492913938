import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = ({setPCart}) => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Hager</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/contactors/current/25A/Hager" className={styles.subMenuItem}>25A</Link>
                <Link onClick={()=>setPCart(false)} to="/contactors/current/40A/Hager" className={styles.subMenuItem}>40A</Link>
                <Link onClick={()=>setPCart(false)} to="/contactors/current/63A/Hager" className={styles.subMenuItem}>63A</Link> 
            </div>            
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ENEXT</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/contactors/current/12A/ENEXT" className={styles.subMenuItem}>12A</Link>
                <Link onClick={()=>setPCart(false)} to="/contactors/current/18A/ENEXT" className={styles.subMenuItem}>18A</Link>
                <Link onClick={()=>setPCart(false)} to="/contactors/current/25A/Hager" className={styles.subMenuItem}>25A</Link>
                <Link onClick={()=>setPCart(false)} to="/contactors/current/40A/ENEXT" className={styles.subMenuItem}>40A</Link>
            </div>            
        </div>
    );
};

export default AutoOff;