import React from "react";
import { Link } from "react-router-dom";
import styles from "./arrayHomePage.module.css";
import useWindowDimensions from './Dimensions.js'; // import the custom hook

const cB = () => {
    
    return (
        <div className={styles.ForFlexColumn}>
            <Link to="/circuitBreakers/poles/3p/ЕТІ" className={styles.itemsColumn}>Триполюсні Hager</Link>
            <Link  to="/circuitBreakers/poles/3p/ENEXT" className={styles.itemsColumn}>Триполюсні E.NEXT</Link>
            <Link  to="/circuitBreakers/capacity/4.5kA/ENEXT" className={styles.itemsColumn}>4.5kA  E.NEXT</Link>
            <Link to="/circuitBreakers/poles/2p/IEK" className={styles.itemsColumn}>Двополюсні IEK</Link>
            <Link to="/circuitBreakers/poles/3p/IEK" className={styles.itemsColumn}>Триполюсні IEK</Link>
            <Link to="/circuitBreakers/current/4.5kA/IEK" className={styles.itemsColumn}>4.5kA IEK</Link>            
        </div>
    );
};
const dyfAuto = () => {
    return (
        <div className={styles.ForFlexColumn}>
            <Link  to="/difautomates/current/10А/ETI" className={styles.itemsColumn}>Струм 10A ETI</Link>
            <Link to="/difautomates/current/16А/ETI" className={styles.itemsColumn}>Струм 16A ETI</Link>
            <Link to="/difautomates/current/25А/Eaton" className={styles.itemsColumn}>Струм 25A Eaton</Link>      
            <Link to="/difautomates/current/32А/Eaton" className={styles.itemsColumn}>Струм 32A Eaton</Link>
            <Link to="/difautomates/current/40A/Schneider" className={styles.itemsColumn}>Струм 40A Schneider</Link>
            <Link to="/difautomates/current/16А/Hager" className={styles.itemsColumn}>Струм 16A Hager</Link>
        </div>
    );
};
const pzb = () => {
    return (
        <div className={styles.ForFlexColumn}>
            <Link to="/pzv/poles/2P/Eaton" className={styles.itemsColumn}>Двополюсний Eaton</Link>
            <Link to="/pzv/poles/4P/Eaton" className={styles.itemsColumn}>Чотирьохполюсний Eaton</Link>
            <Link to="/pzv/current/25A/Eaton" className={styles.itemsColumn}>Ток 25А Eaton</Link>
            <Link  to="/pzv/current/25A/Hager" className={styles.itemsColumn}>Ток 25А Hager</Link>
            <Link  to="/pzv/current/40A/Hager" className={styles.itemsColumn}>Ток 40А Hager</Link>
            <Link to="/pzv/current/63A/Hager" className={styles.itemsColumn}>Ток 63А Hager</Link>
        </div>
    );
};
const contactors = () => {
    return (
        <div className={styles.ForFlexColumn}>
            <Link  to="/contactors/current/25A/Hager" className={styles.itemsColumn}>25A Hager</Link>
            <Link  to="/contactors/current/40A/Hager" className={styles.itemsColumn}>40A Hager</Link>
            <Link to="/contactors/current/63A/Hager" className={styles.itemsColumn}>63A Hager</Link>
            <Link to="/contactors/current/18A/ENEXT" className={styles.itemsColumn}>18A E.NEXT</Link>
            <Link to="/contactors/current/25A/Hager" className={styles.itemsColumn}>25A E.NEXT</Link>
            <Link to="/contactors/current/40A/ENEXT" className={styles.itemsColumn}>40A E.NEXT</Link>
        </div>
    );
};
const switchers = () => {
    return (
        <div className={styles.ForFlexColumn}>
            <Link  to="/switchers/poles/1P/ETI" className={styles.itemsColumn}>Однополюсний ETI</Link>
            <Link  to="/switchers/poles/2P/ETI" className={styles.itemsColumn}>Двополюсний ETI</Link>
            <Link  to="/switchers/poles/3P/ETI" className={styles.itemsColumn}>Трьохполюсний ETI</Link>
            <Link to="/switchers/poles/1P/Hager" className={styles.itemsColumn}>Однополюсний Hager</Link>
            <Link  to="/switchers/poles/2P/Hager" className={styles.itemsColumn}>Двополюсний Hager</Link>
            <Link to="/switchers/poles/3P/Hager" className={styles.itemsColumn}>Трьохполюсний Hager</Link>
        </div>
    );
};
const shields = () => {
    return (
        <div className={styles.ForFlexColumn}>
            <Link  to="/shields/modules/2/ENEXT" className={styles.itemsColumn}>2-модульний E.NEXT</Link>
            <Link  to="/shields/modules/4/ENEXT" className={styles.itemsColumn}>4-модульний E.NEXT</Link>
            <Link  to="/shields/modules/9/АСКО" className={styles.itemsColumn}>9-модульний АСКО</Link>
            <Link  to="/shields/modules/12/АСКО" className={styles.itemsColumn}>12-модульний АСКО</Link>
            <Link  to="/shields/modules/12/ETI" className={styles.itemsColumn}>12-модульний ETI</Link>
            <Link  to="/shields/modules/24/Mutlusan" className={styles.itemsColumn}>24-модульний Mutlusan</Link>
        </div>
    );
};
const lamps = () => {
    return (
        <div className={styles.ForFlexColumn}>
            <Link  to="/lamps/capacity/18 W/DeLux" className={styles.itemsColumn}>Потужність 18W DeLux</Link>  
            <Link  to="/lamps/capacity/20 W/DeLux" className={styles.itemsColumn}>Потужність 20W DeLux</Link>    
            <Link to="/lamps/lumen/4100K/DeLux" className={styles.itemsColumn}>4100K люменів DeLux</Link>
            <Link  to="/lamps/capacity/18 W/Євросвітло" className={styles.itemsColumn}>Потужність 18W Євросвітло</Link>
            <Link  to="/lamps/lumen/4200K/Євросвітло" className={styles.itemsColumn}>4000K люменів Євросвітло</Link>
            <Link to="/lamps/lumen/4200K/Євросвітло" className={styles.itemsColumn}>4200K люменів Євросвітло</Link>
        </div>
    );
};
const cables = () => {
    return (
        <div className={styles.ForFlexColumn}>
            <Link  to="/cables/cores/5/ГАЛ-КАТ"  className={styles.itemsColumn}>П'ятижильний ГАЛ-КАТ</Link>
            <Link to="/cables/section/1,5/ГАЛ-КАТ"  className={styles.itemsColumn}>Переріз 1.5мм ГАЛ-КАТ</Link>
            <Link to="/cables/cores/3/ЗЗКМ"  className={styles.itemsColumn}>Трьохжильний ЗЗКМ</Link>
            <Link to="/cables/cores/2/НІВІТ" className={styles.itemsColumn}>Двожильний НІВІТ</Link>
            <Link to="/cables/section/2,5/ЕСК"  className={styles.itemsColumn}>Переріз 2.5мм ЕСК</Link>
            <Link to="/cables/section/1,5/Одескабель"  className={styles.itemsColumn}>Переріз 1.5мм Одескабель</Link>
        </div>
    );
};
const cablesRS = () => {
    return (
        <div className={styles.ForFlexColumn}>
            <Link to="/ardicandscat/type/Підйом/Ardic" className={styles.itemsColumn}>Тип "Підйом" Ardic</Link>
            <Link to="/ardicandscat/type/Поворот/Ardic" className={styles.itemsColumn}>Тип "Поворот" Ardic</Link> 
            <Link to="/ardicandscat/dimensions/50х50мм/Ardic" className={styles.itemsColumn}>Розмір 50х50мм Ardic</Link> 
            <Link to="/ardicandscat/dimensions/100х50мм/Ardic" className={styles.itemsColumn}>Розмір 100х50мм Ardic</Link>
            <Link to="/ardicandscat/type/Кришка/SCaT" className={styles.itemsColumn}>Тип "Кришка" SCaT</Link>
            <Link to="/ardicandscat/type/Лоток/SCaT" className={styles.itemsColumn}>Тип "Лоток" SCaT</Link>
        </div>
    );
};
const arrForHP = [cB(), dyfAuto(), pzb(), contactors(), switchers(), shields(), lamps(), cables(), cablesRS() ];

export default arrForHP;