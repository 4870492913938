import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = () => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Shneider</h3></Link>
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Hager</h3></Link>
            </div>
        </div>
    );
};

export default AutoOff;