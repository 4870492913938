import React, { useEffect, useState } from 'react';
import styles from './Slideshow.module.css';
import imagesArray from "./imagesMy/ImageMap.js";

const Slideshow = () => {
  const images = [
    imagesArray[0].image1,
    imagesArray[0].image2,
    imagesArray[0].image3,
    imagesArray[0].image4
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const autoSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex < images.length - 1 ? prevIndex + 1 : 0
      );
    };

    const timeoutId = setTimeout(autoSlide, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentIndex, images.length]);

  return (
    <div className={styles.slideshow}>
      <button className={styles.leftButton} onClick={prevSlide}>
        &#9664;
      </button>
      <div className={styles.slide}>
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
      </div>
      <button className={styles.rightButton} onClick={nextSlide}>
        &#9654;
      </button>
      <div className={styles.controls}>
        <div className={styles.indicators}>
          {images.map((_, index) => (
            <span
              key={index}
              className={
                index === currentIndex
                  ? `${styles.indicator} ${styles.active}`
                  : styles.indicator
              }
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
