import React, { useEffect, useState } from "react";
import styles from "./PurchaseCart.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faEllipsisVertical, faTrash, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";


const PurchaseCart = ({ innerElementRef2, forPurchCart }) => {
    const [cartItems, setCartItems] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);    

    // Load cart items from local storage on component mount
    useEffect(() => {
        const storedCartItems = JSON.parse(localStorage.getItem('cart'));
        if (storedCartItems) {
            setCartItems(storedCartItems);
        }
        
    }, [buttonClicked]);    
   
    
    // Function to calculate total price
    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
    };

    const handleInputChange = (itemId, event) => {
        let value = parseFloat(event.target.value);
        // Ensure that the entered value is at least 1
        value = Math.max(value, 1);
        
        // Update quantity of the specific item in cartItems
        setCartItems(prevCartItems => {
            const updatedCartItems = prevCartItems.map(item =>
                item.image === itemId ? { ...item, quantity: value } : item
            );
    
            // Update local storage with the modified cart items array
            localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    
            return updatedCartItems; // Return the updated cart items
        });
    };
    
    
    
    const handleButtonClick = (article) => {
        // Function to delete an item from local storage by its "article" property
        const deleteItemFromLocalStorage = (article) => {
            // Retrieve cart items from local storage
            const storedCartItems = JSON.parse(localStorage.getItem('cart'));
    
            // If cart items exist in local storage
            if (storedCartItems) {
                // Find the index of the item with the matching "article" property
                const index = storedCartItems.findIndex(item => item.image === article);
    
                // If the item is found
                if (index !== -1) {
                    // Remove the item from the cart items array
                    storedCartItems.splice(index, 1);
    
                    // Update local storage with the modified cart items array
                    localStorage.setItem('cart', JSON.stringify(storedCartItems));
                }
            }
        };
    
        // Update state to indicate button is clicked
        setButtonClicked((prevState) => !prevState);
    
        // Call deleteItemFromLocalStorage
        deleteItemFromLocalStorage(article);
    };
    const [iporDet, setIporDet] = useState(false);
    return (
        <div id={styles.entPurchaseContent}>
            <div ref={innerElementRef2} id={styles.innPurchaseContent}>
                <div id={styles.innPurConHead}>
                    <div id={styles.nameBin}>Кошик</div>
                    <div id={styles.closeBin} onClick={forPurchCart}><FontAwesomeIcon icon={faXmark} /></div>
                </div>
                <div id={styles.innPurConBody}>
                    {cartItems.length == 0 && <div>Пусто</div>}
                    {cartItems.map((item, index) => (
                        <div key={index} className={styles.cartItems}>
                            <div className={styles.topCartItem}>
                                <div className={styles.cartImage}>
                                    <img src={`http://elektryka.kyiv.ua/${item.image}.jpg`} />
                                </div>
                                <div className={styles.cartName}>{item.name}</div>
                                <div className={styles.cart3signItem}><FontAwesomeIcon className={styles.cart3signItemInner} onClick={() => handleButtonClick(item.image)} icon={faTrash} /></div>
                            </div>
                            <div className={styles.bottomCartItem}>
                                <div className={styles.priceCartItem}>
                                    <div className={styles.cartAmount}>
                                        <button
                                            onClick={() => {
                                                const updatedQuantity = Math.max(item.quantity - 1, 1); // Decrease the quantity by 1, but ensure it's at least 1
                                                setCartItems(prevCartItems => {
                                                    const updatedCartItems = prevCartItems.map(cartItem =>
                                                        cartItem.image === item.image ? { ...cartItem, quantity: updatedQuantity } : cartItem
                                                    );

                                                    localStorage.setItem('cart', JSON.stringify(updatedCartItems)); // Update local storage
                                                    return updatedCartItems; // Return the updated cart items
                                                });
                                            }}
                                            className={styles.minusButton}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </button>
                                        <input
                                            className={styles.cartInput}
                                            type="number"
                                            value={item.quantity}
                                            min="1"
                                            onChange={(event) => handleInputChange(item.image, event)}
                                            placeholder={`Enter quantity for ${item.name}`}
                                        />
                                        <button
                                            onClick={() => {
                                                const updatedQuantity = item.quantity + 1; // Increase the quantity by 1
                                                setCartItems(prevCartItems => {
                                                    const updatedCartItems = prevCartItems.map(cartItem =>
                                                        cartItem.image === item.image ? { ...cartItem, quantity: updatedQuantity } : cartItem
                                                    );

                                                    localStorage.setItem('cart', JSON.stringify(updatedCartItems)); // Update local storage
                                                    return updatedCartItems; // Return the updated cart items
                                                });
                                            }}
                                            className={styles.plusButton}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </button>
                                    </div>
                                    <div className={styles.cartItemPrice}>{item.price} ₴</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div id={styles.innPurConApply}>
                    {
                        cartItems.length > 0 &&
                        <div id={styles.cartBuy}>
                        <div id={styles.resultSum}>{calculateTotal()} ₴</div>
                        <Link to="/CheckOut" id={styles.proceedCheckOut}>Оформити замовлення</Link>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
    
};


export default PurchaseCart;