import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = () => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ETI</h3></Link>  
            </div>            
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Eaton</h3></Link>
                <Link to="" className={styles.subMenuItem}>Однополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Двополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Триполюсні</Link>
                <Link to="" className={styles.subMenuItem}>Тип С</Link>               
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>E. NEXT</h3></Link>
                <Link to="" className={styles.subMenuItem}>Однополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Двополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Триполюсні</Link>
                <Link to="" className={styles.subMenuItem}>4.5kA</Link>
                <Link to="" className={styles.subMenuItem}>6kA</Link>                
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ІЕК</h3></Link>
                <Link to="" className={styles.subMenuItem}>Однополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Двополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Триполюсні</Link>
                <Link to="" className={styles.subMenuItem}>4.5kA</Link>
                <Link to="" className={styles.subMenuItem}>10kA</Link>
                <Link to="" className={styles.subMenuItem}>35kA</Link>                
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Shneider</h3></Link>
                <Link to="" className={styles.subMenuItem}>Однополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Двополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Триполюсні</Link>
                <Link to="" className={styles.subMenuItem}>Чотирьохполюсні</Link>
                <Link to="" className={styles.subMenuItem}>Тип С</Link>
                <Link to="" className={styles.subMenuItem}>Тип B</Link>
                <Link to="" className={styles.subMenuItem}>6kA</Link>  
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Hager</h3></Link>
                <Link to="" className={styles.subMenuItem}>Однополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Двополюсні</Link>
                <Link to="" className={styles.subMenuItem}>Триполюсні</Link>
                <Link to="" className={styles.subMenuItem}>Чотирьохполюсні</Link>
                <Link to="" className={styles.subMenuItem}>Тип С</Link>
                <Link to="" className={styles.subMenuItem}>Тип D</Link>
                <Link to="" className={styles.subMenuItem}>6kA</Link>
                <Link to="" className={styles.subMenuItem}>10kA</Link>
                <Link to="" className={styles.subMenuItem}>18kA</Link>
            </div>
        </div>
    );
};

export default AutoOff;