import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import styles from "../Main.module.css";
import Header from "../Header.js";
import MainContent2 from "./Maincontent2.js";
import Footer from "../Footer.js";



const ProductPage = ({DrDo, setDrDo}) => {        
    
    return(
        <div id={styles.wrapper}>            
            <div id={styles.faq}></div>
            <Header setDrDo={setDrDo} DrDo={DrDo} />
            <MainContent2 setDrDo={setDrDo} DrDo={DrDo} />
            <Footer />            
        </div>
    );
};
export default ProductPage;