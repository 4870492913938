import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = ({setPCart}) => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ГАЛ-КАТ</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/2/ГАЛ-КАТ" className={styles.subMenuItem}>Двожильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/3/ГАЛ-КАТ"  className={styles.subMenuItem}>Трьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/4/ГАЛ-КАТ"  className={styles.subMenuItem}>Чотирьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/5/ГАЛ-КАТ"  className={styles.subMenuItem}>П'ятижильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/1,5/ГАЛ-КАТ"  className={styles.subMenuItem}>Переріз 1.5мм</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/2,5/ГАЛ-КАТ"  className={styles.subMenuItem}>Переріз 2.5мм</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/4,0/ГАЛ-КАТ"  className={styles.subMenuItem}>Переріз 4мм</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/6,0/ГАЛ-КАТ"  className={styles.subMenuItem}>Переріз 6мм</Link>
            </div>            
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ЗЗКМ</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/2/ЗЗКМ" className={styles.subMenuItem}>Двожильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/3/ЗЗКМ"  className={styles.subMenuItem}>Трьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/4/ЗЗКМ"  className={styles.subMenuItem}>Чотирьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/5/ЗЗКМ"  className={styles.subMenuItem}>П'ятижильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/1,5/ЗЗКМ"  className={styles.subMenuItem}>Переріз 1.5мм</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/2,5/ЗЗКМ"  className={styles.subMenuItem}>Переріз 2.5мм</Link>     
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>НІВІТ</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/2/НІВІТ" className={styles.subMenuItem}>Двожильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/3/НІВІТ"  className={styles.subMenuItem}>Трьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/4/НІВІТ"  className={styles.subMenuItem}>Чотирьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/5/НІВІТ"  className={styles.subMenuItem}>П'ятижильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/1,5/НІВІТ"  className={styles.subMenuItem}>Переріз 1.5мм</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/2,5/НІВІТ"  className={styles.subMenuItem}>Переріз 2.5мм</Link>     
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ЕСК</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/2/ЕСК" className={styles.subMenuItem}>Двожильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/3/ЕСК"  className={styles.subMenuItem}>Трьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/4/ЕСК"  className={styles.subMenuItem}>Чотирьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/1,5/ЕСК"  className={styles.subMenuItem}>Переріз 1.5мм</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/2,5/ЕСК"  className={styles.subMenuItem}>Переріз 2.5мм</Link>         
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Одескабель</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/2/Одескабель" className={styles.subMenuItem}>Двожильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/3/Одескабель"  className={styles.subMenuItem}>Трьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/4/Одескабель"  className={styles.subMenuItem}>Чотирьохжильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/cores/5/Одескабель"  className={styles.subMenuItem}>П'ятижильний</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/1,5/Одескабель"  className={styles.subMenuItem}>Переріз 1.5мм</Link>
                <Link onClick={()=>setPCart(false)} to="/cables/section/2,5/Одескабель"  className={styles.subMenuItem}>Переріз 2.5мм</Link>              
            </div>            
        </div>
    );
};

export default AutoOff;