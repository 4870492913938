import React, { useEffect, useRef, useState } from "react";
import styles from "./Maincontent2.module.css";
import styles2 from "../Maincontent.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faEthernet } from '@fortawesome/free-solid-svg-icons';
import { faTemperatureHigh, faShuffle, faScrewdriverWrench, faToggleOff, faCircleNodes, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { faPlug, faRoute, faPager, faRepeat, faTemperatureArrowDown, faClockRotateLeft, faPlusMinus, faShield, faXmark} from '@fortawesome/free-solid-svg-icons';
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import HomePage from "../HomePage.js";
import axios from "axios";
import images from "../imagesMy/ImageMap.js";
import SubMenuElement from "../SubMenuElement.js"


const MainContent = ({DrDo, setDrDo}) => {
    const item1 = {
        id:1,
        color: "red"
    };
    
    // State to keep track of the clicked element and whether to add a new element
    const [clickedElementIndex, setClickedElementIndex] = useState(null);
    // Side menu items array
    
    
    
    const handleAddToCart = (item) => {
        // Retrieve cart items from local storage or initialize an empty array if it doesn't exist
        let cartItems = JSON.parse(localStorage.getItem('cart')) || [];        
        // Add the new item to the cart
        cartItems.push({price: item.price, quantity:1, image: item.article, name: item.name});
        // Save the updated cart data back to local storage
        localStorage.setItem('cart', JSON.stringify(cartItems));
        setIoep(true);
    };
    const buttonRef3 = useRef(null);
    const [currentPage, setCurrentPage] = useState(1); // Initialize to the first page
    const { andWhatPr, andWhat} = useParams();
    const [productPg, setProductPg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [ioep, setIoep] = useState(false);

    // Define the base URL as an environment variable or configuration
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://poloelektro.com.ua';
    
    // Define the endpoint URL using the base URL and `andWhatPr` parameter
    const uRI = `${baseUrl}/api/${andWhat}/${andWhatPr}`;

    // Function to fetch products from the API
    const fetchProducts = async () => {
        try {
            setIsLoading(true); // Start loading
            const response = await axios.get(uRI);
            console.log(uRI);
            
            // Check if the response data contains the expected product structure
            if (response.data && response.data.product) {
                // Update state with fetched product
                setProductPg(response.data.product);
            } else {
                // Handle unexpected API response structure
                console.error('Unexpected API response structure');
                setError('Failed to fetch the product. Unexpected API response structure.');
            }
        } catch (error) {
            console.error('Error fetching products:', error);
            setError('Error fetching products. Please try again later.');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    // Fetch products when the component mounts, `currentPage`, or `andWhatPr` changes
    useEffect(() => {
        fetchProducts();
    }, [currentPage, andWhatPr]);
    const [tyr, setTyr] = useState(false);
    const innerElementRef3 = useRef(null);
    const lfso = () => {
        setTyr((prevState) => !prevState);
    };
    const increaseIm = () => {
        return tyr === false ? styles.hiddenImageCont : styles.nothiddenImageCont;
    };
    useEffect(()=>{
        const handleClickOutside = (event) => {
            if (buttonRef3.current && !buttonRef3.current.contains(event.target) && 
                innerElementRef3.current && !innerElementRef3.current.contains(event.target)) {
                    setTyr(false);
            }    
            
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[]);
    const brandImages = {
        Hager: images[1].hager,
        Mutlusan: images[1].mutlusan,
        ETI: images[1].eti,
        ЕТІ: images[1].eti, // Cyrillic
        Schneider: images[1].schneider,
        ENEXT: images[1].enext,
        "E.NEXT": images[1].enext,
        ЕНЕКСТ: images[1].enext,
        EATON: images[1].eaton,
        Аско: images[1].asko,
        АСКО: images[1].asko,
        Євросвітло: images[1].evrosvitlo,
        DELUX: images[1].delux,
        DigiTOP: images[1].digitop,
        DigiTop: images[1].digitop,
        Новатек: images[1].novatek,
        ZUBR: images[1].zubr,
        IEK: images[1].iek
      };
      
    return (
        <div id={styles2.mainContainer}>
            <div id={increaseIm()}>            
               {productPg && 
                    <div>
                        <div onClick={()=>lfso()} id={styles.closeImage}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div ref={buttonRef3}>
                            <img ref={innerElementRef3} id={styles.ImageForHidden} src={`https://elektryka.kyiv.ua/${productPg.article}.jpg`} alt={productPg.article}  />
                        </div>
                        
                    </div>
               } 
            </div>          
            <SubMenuElement />
            <div id={styles.subConContent}>     

                    {isLoading && <div className={styles.loader}></div>}
                    {error && <div>{error}</div>}
                    {productPg && (
                        <div id={styles.productInfo}>
                            
                            <div id={styles.firstRow}>
                                <div id={styles.prImage}>
                                    <img onClick={()=>lfso()} src={`https://elektryka.kyiv.ua/${productPg.article}.jpg`} alt={productPg.article}  />
                                    <div id={styles.purchaseSection}>
                                        <div id={styles.prPrice}> <span>{productPg.price} грн</span></div>
                                        <button onClick={() => handleAddToCart(productPg)} id={styles.buyButton}>В кошик</button>
                                    </div>
                                </div>
                            <div id={styles.prDescript}>
                                {productPg.name && <div id={styles.prName}><h2>{productPg.name}</h2></div>}
                                {productPg.code && <div id={styles.prName}><h5>{productPg.code}</h5><span className={styles.isonWare}>Є в наявності</span></div>}
                                {productPg.article && <div id={styles.prArticle}><div className={styles.leftDescript}>Артикул: </div><div className={styles.rightDescript}>{productPg.article}</div></div>}
                                {productPg.type && <div id={styles.prType}><div className={styles.leftDescript}>Тип: </div> <div className={styles.rightDescript}>{productPg.type}</div></div>}
                                {productPg.manufacturer && <div id={styles.prManuf}><div className={styles.leftDescript}>Виробник: </div> <div className={styles.rightDescript}> {productPg.manufacturer}</div></div>}
                                {productPg.material && <div id={styles.prMater}><div className={styles.leftDescript}>Матеріал основи: </div> <div className={styles.rightDescript}>{productPg.material}</div></div>}
                                {productPg.capacity && <div id={styles.prMater}><div className={styles.leftDescript}>Потужність: </div> <div className={styles.rightDescript}>{productPg.capacity}</div></div>}
                                {productPg.current && <div id={styles.prMater}><div className={styles.leftDescript}>Ток: </div> <div className={styles.rightDescript}>{productPg.current}</div></div>}
                                {productPg.brand && <div id={styles.prMater}><div className={styles.leftDescript}>Виробник: </div> <div className={styles.rightDescript}>
                                    <img id={styles.forBrandImage} src={brandImages[productPg.brand]} alt={productPg.brand} />                       
                                </div></div>}
                                {productPg.poles && <div id={styles.prMater}><div className={styles.leftDescript}>Полюсність: </div> <div className={styles.rightDescript}>{productPg.poles}</div></div>}
                                {productPg.sensitivity && <div id={styles.prMater}><div className={styles.leftDescript}>Струм витоку: </div> <div className={styles.rightDescript}>{productPg.sensitivity}</div></div>}
                                {productPg.voltage && <div id={styles.prMater}><div className={styles.leftDescript}>Вольтаж: </div> <div className={styles.rightDescript}>{productPg.voltage}</div></div>}
                                {productPg.current && <div id={styles.prMater}><div className={styles.leftDescript}>Ток: </div> <div className={styles.rightDescript}>{productPg.current}</div></div>}
                                {productPg.lumen && <div id={styles.prMater}><div className={styles.leftDescript}>Колір світіння: </div> <div className={styles.rightDescript}>{productPg.lumen}</div></div>}
                                {productPg.zokole && <div id={styles.prMater}><div className={styles.leftDescript}>Цоколь: </div> <div className={styles.rightDescript}>{productPg.zokole}</div></div>}
                                {productPg.cores && <div id={styles.prMater}><div className={styles.leftDescript}>Кількість жил: </div> <div className={styles.rightDescript}>{productPg.cores}</div></div>}
                                {productPg.section && <div id={styles.prMater}><div className={styles.leftDescript}>Переріз: </div> <div className={styles.rightDescript}>{productPg.section}</div></div>}
                                <div id={styles.prDelivery}>
                                    <div className={styles.clDelivery}><strong>Доставка</strong><br /><p>Самовивіз у місті Київ (вулиця Шахтарська 5, Київ) або доставка по всій Україні до відділень та поштоматів Нова Пошта або службою доставки Delivery  </p></div>
                                    <div className={styles.clDelivery}><strong>Гарантія</strong><br /><p>Повернення товару належної якості протягом 14 днів</p></div>
                                </div>
                            </div>
                        </div>
                        <div id={styles.secondRow}>
                                <div id={styles.prDesc}>{productPg.description}</div>
                            </div>
                        </div>
                    )}                
            </div>
        </div>
    );
};
export default MainContent;