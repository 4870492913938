import styles from './App.module.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from "./ForMain/Main.js";
import Render from "./ForMain/Render/Render.js";
import Render2 from "./ForMain/Render/Render2.js";
import ProductPage from "./ForMain/Render/ProductPage.js";
import CheckOut from './ForMain/CheckOut.js';
import ContactsPage from "./ForMain/Contacts.js";
import News from "./ForMain/News.js";
import Sales from "./ForMain/Sales.js";
import OrderSuccess from "./ForMain/SuccessEmail.js";
import Render3 from "./ForMain/Render/Render3.js";
import Render4 from "./ForMain/Render/Render4.js";
import WeekGood from './ForMain/WeekGood.js';

function App() {
  
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Main />}>
              <Route path="subcateg/:andWhat" element={<Render />}>
                   
              </Route>
              <Route path=":andWhat1/:andWhat2" element={<Render2 />}>              
              </Route>
              <Route path=":andWhat1/:andWhat2/:andWhat3/:andWhat4" element={<Render3 />}></Route>
              <Route path=":andWhat1" element={<Render4 />}></Route>
              <Route path="contacts" element={<ContactsPage />}>
                   
              </Route>    
              <Route path="news" element={<News />}>
                   
              </Route> 
              <Route path="sales" element={<Sales />}>
                   
              </Route>  
              <Route path="weekgood" element={<WeekGood />}>
                   
              </Route>                                    
            </Route>
            <Route path="/CheckOut" element={<CheckOut />} />
            <Route path="/subcateg/:andWhat/:andWhatPr" element={<ProductPage />} />
            <Route path="/checkqweqwe" element={<OrderSuccess />} />
        </Routes>
    </Router>
    
  );
}

export default App;

