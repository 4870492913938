import React from 'react';
import styles from './ContactsPage.module.css';

const ContactsPage = () => {
  return (
    <div className={styles.contactsPage}>
      <h1>Наші контакти</h1>      
      <div className={styles.contactInfo}>        
        <p>вулиця Шахтарська 5, Київ, Україна</p>
        <p>Телефон: (097) 191-0420</p>
        <p>Телефон: (097) 422-4813</p>
        <p>Email: <a href="mailto:company.poloelektro@gmail.com">company.poloelektro@gmail.com</a></p>
      </div>

      <div className={styles.map}>
        <div>
          <iframe
            title="Company Location"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDzXt_9q3HEAXwp7-4JdQWMpICcJnmilCM&q=Shakhtarska,Kyiv+Ukraine&zoom=14&maptype=satellite"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>         
      </div>      
    </div>
  );
};

export default ContactsPage;
