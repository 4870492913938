import React from "react";

const images = {
    image1: require("./firstSlide.jpg"),
    image2: require("./secondSlide.jpg"),
    image3: require("./thirdSlide.jpg"),
    image4: require("./fourthSlide.jpg")
};
const images2 = {
    schneider: require("./schneider.jpg"),
    mutlusan: require("./mutlusan.jpg"),
    hager: require("./hager.jpg"),
    eti: require("./eti.jpg"),
    enext: require("./enext.jpg"),
    eaton: require("./eaton.jpg"),
    iek: require("./iek.jpg"),
    evrosvitlo: require("./evrosvitlo.jpg"),
    delux: require("./delux.jpg"),
    digitop: require("./digitop.jpg"),
    novatek: require("./novatek.jpg"),
    zubr: require("./zubr.jpg"),
    asko: require("./asko.jpg")
};
const imagesArray = [images, images2];
export default imagesArray;
