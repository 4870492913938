import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import styles from './Render.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

const ProductList = () => {
    // Initialize state variables
    const [products, setProducts] = useState([]); // Initialize as an empty array
    const [totalProducts, setTotalProducts] = useState(0); // Initialize to 0
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Initialize to the first page
    const currentUrl = window.location.href;
   
    const { andWhat1, andWhat2, andWhat3, andWhat4 } = useParams();
    const uRI = `https://poloelektro.com.ua/prod/${andWhat1}/${andWhat2}/${andWhat3}/${andWhat4}`;
    const [ioep, setIoep] = useState(false);
   

    // Function to fetch products from the API
    const fetchProducts = async () => {
        try {
            setIsLoading(true); // Start loading
            const response = await axios.get(uRI);
            // Handle the case where the API response structure may not match the expected format
            if (response.data && response.data.products) {
                console.log(response.data.products);
                setProducts(response.data.products); // Update state with fetched products
                setTotalProducts(response.data.totalProducts); // Update state with total products
            } else {
                // Handle unexpected API response structure
                console.error('Unexpected API response structure');
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    // Fetch products when the component mounts or currentPage changes
    useEffect(() => {
        fetchProducts();
    }, [currentPage, currentUrl]);

    // Function to handle page changes
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    // Calculate the total number of pages
    const totalPages = Math.ceil(totalProducts / 25);
    const [pagColor, setPagColor] = useState({
        button1: true,
        button2: false,
        button3: false,
        button4: false,
        button5: false,
        button6: false,
        button7: false,
        button8: false,
        button9: false,
        button10: false,
        button11: false,
        button12: false,
        button13: false,
        button14: false,
        button15: false
    });
    const myChanger = (buttonID) => {
        for (let i in pagColor) {
            pagColor[i] = false;
        }
        setPagColor((prevState) => ({
            ...prevState,
            [buttonID]: true
        }));
    };
    const colorChange = (buttonID) => {
        return pagColor[buttonID] === true ? styles.forGreen : styles.forDefault;        
    };
    const Yaho = (buttonID) => {
        myChanger(buttonID);
        setCurrentPage(()=>{
           if (buttonID==='button1'){
            return 1;
           } else if (buttonID==='button2') {
            return 2;
           } else if (buttonID==='button3') {
            return 3;
           } else if (buttonID==='button4') {
            return 4;
           } else if (buttonID==='button5') {
            return 5;
           } else if (buttonID==='button6') {
            return 6;
           } else if (buttonID==='button7') {
            return 7;
           } else if (buttonID==='button8') {
            return 8;
           } else if (buttonID==='button9') {
            return 9;
           } else if (buttonID==='button10') {
            return 10;
           } else if (buttonID==='button11') {
            return 11;
           } else if (buttonID==='button12') {
            return 12;
           } else if (buttonID==='button13') {
            return 13;
           } else if (buttonID==='button14') {
            return 14;
           } else if (buttonID==='button15') {
            return 15;
           }
           
        });
    };
    
    const handleAddToCart = (item) => {
        // Retrieve cart items from local storage or initialize an empty array if it doesn't exist
        let cartItems = JSON.parse(localStorage.getItem('cart')) || [];        
        // Add the new item to the cart
        cartItems.push({price: item.price, quantity:1, image: item.article, name: item.name});
        // Save the updated cart data back to local storage
        localStorage.setItem('cart', JSON.stringify(cartItems));
        setIoep(true);
    };
    const fi = () => {
        const elements = [];
        for (let i = 1; i < totalPages+1; i++) {
            elements.push(
                <button className={colorChange(`button${i}`)}  onClick={() => Yaho(`button${i}`)}>
                        {i}
                </button>
            );
        }
        return elements;
    };    
    
    const [sortOrder, setSortOrder] = useState('asc'); // Initial sort order is 'asc'

    // Fetch goods when the component mounts or when the sortOrder changes




    const handleSortClick = () => {
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const sortedProducts = products.sort((a, b) => {
        if (sortOrder === 'asc') {
            return a.price - b.price;
        } else {
            return b.price - a.price;
        }
    });
   
    return (
        <div id={styles.mainRenderWrap}>            
            <div id={styles.contenteWrapper}>
            <div id={styles.contentFilters}>
                <button onClick={handleSortClick} className={styles.ASCButton}>
                    Сортування ({sortOrder === 'asc' ? 'за зростанням ціни' : 'за зменшенням ціни'})
                </button>
            </div>
                <div id={styles.forContentRender}>
                {isLoading && <p>Loading...</p>}
                {!isLoading && products.length === 0 && <p>No products found.</p>}
                    {sortedProducts.map(product => (
                        <div  className={styles.productCard} key={product.id}>
                            <Link className={styles.allProdCont} to={`../subcateg/${andWhat1}/${product.article}`}>
                                <div className={styles.imageContainer}>
                                    <img src={`https://elektryka.kyiv.ua/${product.article}.jpg`} alt={product.name} className={styles.productImage} />
                                </div>
                                <div className={styles.productDetails}>
                                    <h4 className={styles.productName}>{product.name}</h4>                                    
                                    {product.price && <p className={styles.productPrice}>{product.price} ₴</p>}                            
                                </div>
                            </Link>
                            <button className={styles.addToCartButton} onClick={() => handleAddToCart(product)}>
                                 <span><FontAwesomeIcon icon={faCartShopping} /></span>   В кошик
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            
            {/* Pagination controls */}
            <div className={styles.paginationControls}>
                {fi()}                             
            </div>
            
        </div>
    );
};


export default ProductList;
