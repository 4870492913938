import React from "react";
import styles from "./HomePage.module.css";
import Slideshow from "./SlideShow.js";
import HomePageImages from "./imagesHomePage/imagesMapHomePage.js";
import { Link } from "react-router-dom";
import arrForHP from "./arrayHomePage.js";

const HomePage = () => {
    const sideMenuItemsSubCon = [
        "Автоматичні вимикачі",
        "Диференційні автомати",
        "ПЗВ",
        "Контактори",
        "Рубильники та роз'єднувачі",
        "Щити розподільні",
        "Лампи, світильники, прожектори",   
        "Кабель, провід",
        "Системи прокладки кабелю"  
    ];
    const sideMenuItems2 = [
        "circuitBreakers",
        "differentialCircuitBreakers",
        "residualCurrentDevices",
        "contactors",
        "switchDisconnectorsAndDisconnectors",
        "distributionPanels",
        "lampsLightFixturesSpotlights",
        "cableWire",
        "cableManagementSystems"
    ];   
    return (
        <div id={styles.conMainWrapper}>
            <div id={styles.mainConSlide}>
                <Slideshow />
            </div>
            <div id={styles.conMainConWrap}>
                {   
                    sideMenuItemsSubCon.map((item, index) => {
                        let foe = sideMenuItems2[index];
                        let poa = arrForHP[index];                        
                        return (
                            <div className={styles.conMainConElem}>
                                <div className={styles.nameHPcat}>
                                    <p>{item}</p>
                                </div>
                                <div className={styles.belowHPcat}>
                                    <div className={styles.imageHPcat}>
                                        <img src={HomePageImages[foe]} />
                                    </div>
                                    <div className={styles.subHPcats}>
                                        {poa}
                                    </div>
                                </div>                                                        
                            </div>
                        );
                    })
                }
            </div>
        </div>
        
    );
};

export default HomePage;