import React, { useState } from 'react';
import axios from 'axios';
import styles from "./SearchElement.module.css";
import { Link } from 'react-router-dom';

const SearchComponent = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isonLoad, setIsonLoad] = useState(false);

    const handleChange = async (e) => {
        const value = e.target.value;
        setQuery(value);

        if (value.trim() === '') {
            setResults([]);
            setShowDropdown(false);
            return;
        }

        try {
            setIsonLoad(true);
            const response = await axios.get(`https://poloelektro.com.ua/search?q=${value}`);
            setResults(response.data);
            setShowDropdown(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsonLoad(false);
        };
    };

    const handleBlur = () => {
        // Delay the closing of the dropdown to ensure clicks register
        setTimeout(() => setShowDropdown(false), 200);
    };

    const handleFocus = () => {
        if (results.length > 0) {
            setShowDropdown(true);
        }
    };

    const handleLinkClick = () => {
        setShowDropdown(false);
    };

    return (
        
        <div id={styles.searchDiv}>
            <input
                type="text"
                value={query}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Search..."
            />
            
            {showDropdown && (
                <div id={styles.searchResultsDiv}>
                    {results.map((item) => (
                        <Link
                            className={styles.resultItems}
                            to={`/subcateg/${item.table_name}/${item.article}`}
                            key={item.id}
                            onClick={handleLinkClick} // Close the dropdown when a link is clicked
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchComponent;
