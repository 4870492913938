import React, { useEffect, useState } from "react";
import styles from "./Maincontent.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faEthernet , faShuffle, faPowerOff,faPlusMinus, faShield, faToggleOff,faClockRotateLeft, faPager    } from '@fortawesome/free-solid-svg-icons';
import { faCircleNodes, faScrewdriverWrench, faTemperatureArrowDown, faRepeat } from '@fortawesome/free-solid-svg-icons';
import { faPlug, faRoute} from '@fortawesome/free-solid-svg-icons';
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import HomePage from "./HomePage.js";
import SubMenuElement from "./SubMenuElement.js";

const MainContent = () => {
  
    // Use the useLocation hook to access the current location
    const location = useLocation();
    
    // Check if the current URL is the root URL
    const isRootURL = location.pathname === '/';
    
    // Function to return a component based on the URL
    const mop = () => {
        return isRootURL ? <HomePage /> : <Outlet />;
    };
    return (
        <div id={styles.mainContainer}>
            <SubMenuElement />
            <div id={styles.subConContent}>{mop()}</div>
        </div>
    );
};
export default MainContent;