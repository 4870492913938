import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = ({setPCart}) => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Ardic</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/type/Відгалуджувач/Ardic" className={styles.subMenuItem}>Тип "Відгалуджувач"</Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/type/Консоль/Ardic" className={styles.subMenuItem}>Тип "Консоль"</Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/type/Кришка/Ardic"  className={styles.subMenuItem}>Тип "Кришка"</Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/type/Кронштейн/Ardic" className={styles.subMenuItem}>Тип "Кронштейн"</Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/type/Лоток/Ardic" className={styles.subMenuItem}>Тип "Лоток"</Link>    
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/type/Підйом/Ardic" className={styles.subMenuItem}>Тип "Підйом"</Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/type/Поворот/Ardic" className={styles.subMenuItem}>Тип "Поворот"</Link> 
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/dimensions/50х50мм/Ardic" className={styles.subMenuItem}>Розмір 50х50мм</Link> 
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/dimensions/100х50мм/Ardic" className={styles.subMenuItem}>Розмір 100х50мм</Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/dimensions/200х50мм/Ardic" className={styles.subMenuItem}>Розмір 200х50мм</Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/dimensions/300х50мм/Ardic" className={styles.subMenuItem}>Розмір 300х50мм</Link>        
            </div>        
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>SCaT</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/type/Кришка/SCaT" className={styles.subMenuItem}>Тип "Кришка"</Link>
                <Link onClick={()=>setPCart(false)} to="/ardicandscat/type/Лоток/SCaT" className={styles.subMenuItem}>Тип "Лоток"</Link>             
            </div>    
            
        </div>
    );
};

export default AutoOff;