import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = ({setPCart}) => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>DeLux</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/capacity/7 W/DeLux" className={styles.subMenuItem}>Потужність 7W</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/capacity/10 W/DeLux" className={styles.subMenuItem}>Потужність 10W</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/capacity/12 W/DeLux" className={styles.subMenuItem}>Потужність 12W</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/capacity/18 W/DeLux" className={styles.subMenuItem}>Потужність 18W</Link>  
                <Link onClick={()=>setPCart(false)} to="/lamps/capacity/20 W/DeLux" className={styles.subMenuItem}>Потужність 20W</Link>    
                <Link onClick={()=>setPCart(false)} to="/lamps/lumen/4100K/DeLux" className={styles.subMenuItem}>4100K люменів</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/lumen/6500K/DeLux" className={styles.subMenuItem}>6500K люменів</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/zokole/E27/DeLux" className={styles.subMenuItem}>Цоколь E27</Link> 
            </div>            
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Євросвітло</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/capacity/7 W/Євросвітло" className={styles.subMenuItem}>Потужність 7W</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/capacity/10 W/Євросвітло" className={styles.subMenuItem}>Потужність 10W</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/capacity/12 W/Євросвітло" className={styles.subMenuItem}>Потужність 12W</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/capacity/18 W/Євросвітло" className={styles.subMenuItem}>Потужність 18W</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/lumen/4200K/Євросвітло" className={styles.subMenuItem}>4000K люменів</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/lumen/4200K/Євросвітло" className={styles.subMenuItem}>4200K люменів</Link>
                <Link onClick={()=>setPCart(false)} to="/lamps/lumen/6400K/Євросвітло" className={styles.subMenuItem}>6400K люменів</Link>                 
                <Link onClick={()=>setPCart(false)} to="/lamps/zokole/E27/Євросвітло" className={styles.subMenuItem}>Цоколь E27</Link>   
            </div>
        </div>
    );
};

export default AutoOff;