import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./DropDown.module.css";
import useWindowDimensions from './Dimensions.js'; // import the custom hook
import MyFirstMenu from "./MyFirstMenu.js";

const SubmenuMain = ({sideMenuItems, forHeadIcons, handleItemHover, setDrDo, forHeadCon}) => {
    const [menu, setMenu] = useState(false);
      
    const sideMenuLinks = [
        "subcateg/circuitBreakers",
        "differentialCircuitBreakers",
        "residualCurrentDevices",
        "contactors",
        "switchDisconnectorsAndDisconnectors",
        "timersRelays",
        "distributionPanels",
        "lampsLightFixturesSpotlights",
        "socketsAndSwitches",
        "cableWire",
        "cableManagementSystems",
        "hardwareForSIP",
        "thermostats",
        "automaticTransferSwitches",
        "voltageRelays"
    ];
    const hello = (index) => {
        setMenu((prevState) => true);
        
      };
    const foper = (item) => {
            handleItemHover(item);
            hello(item);
    };
    const { width } = useWindowDimensions();
    const forWidthFunct = (item) => {
        return width < 600 ? { onClick: () => foper(item) } : {};
      };
    return (
        
        <div id = { styles.sideMenuHeadDrDo } >
            {/* Map over the side menu items and render each item */ }
    {
        sideMenuItems.map((item, index) => {
            const correspondingIcon = forHeadIcons[index]; // Get the corresponding icon
            const forLinks = sideMenuLinks[index];
            return (
                <div
                    to={`../${forLinks}`}
                    key={index}
                    className={styles.sideMenuItem}
                    onMouseEnter={() => handleItemHover(item)}
                    {...forWidthFunct(item)}
                >
                    <p className={styles.drDownIcons}>{correspondingIcon}</p> {/* Display the corresponding icon */}
                    <p className={styles.drDownItems}>{item}</p>                    
                </div>
            );
        })
    }    {menu && <MyFirstMenu handleItemHover={handleItemHover} setMenu={setMenu} setDrDo={setDrDo} sideMenuItems={sideMenuItems} forHeadCon={forHeadCon}  />}
    <Link to="/weekgood" onClick={()=>setDrDo(false)} className={`${styles.sideMenuItem} ${styles.saleItem}`}>Товар тижня</Link>    
        </div>
    );
};
export default SubmenuMain;