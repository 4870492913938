/* global grecaptcha */
import React, { useEffect, useState } from "react";
import styles from "./Maincontent3.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircle } from '@fortawesome/free-solid-svg-icons';
import LogoNewPost from "./imagesMy/LogoNewPost.png";

const MainContent3 = () => {
    const [cartItems, setCartItems] = useState([]);
    const [errorM, setErrorM] = useState(false);
    const [formData, setFormData] = useState({
        notes: '',
        phone: '',
        name: '',
        surname: '',
        city: '',
        department: '',
        selfSerf: false
    });
    const [recaptchaReady, setRecaptchaReady] = useState(false);
    const [postCityList, setPostCityList] = useState(false);
    const [deliveryFromPart, setDeliveryFromPart] = useState(true);
    const [deliveryFromPost, setDeliveryFromPost] = useState(false);
    const [poll, setPoll] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const storedCartItems = JSON.parse(localStorage.getItem('cart'));
        if (storedCartItems) {
            setCartItems(storedCartItems);
        }

        const loadReCaptchaScript = () => {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=6LfFvfkpAAAAANFzRiLQnBMpRTNeQ1Ndm6UAtrGk`;
            script.async = true;
            script.onload = () => setRecaptchaReady(true);
            document.body.appendChild(script);
        };

        loadReCaptchaScript();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (!recaptchaReady) {
                console.error("reCAPTCHA not ready");
                return;
            } else if (formData.notes === '' || formData.name === '' || formData.phone === '' || ((formData.department === '' || formData.city === '') && formData.selfSerf === false) ) {
                console.error("Notes are required");
                setErrorM(true);
                setTimeout(() => setErrorM(false), 2000);
                return;
            }

            const recaptchaToken = await grecaptcha.execute('6LfFvfkpAAAAANFzRiLQnBMpRTNeQ1Ndm6UAtrGk', { action: 'submit' });

            const response = await axios.post("https://poloelektro.com.ua/send-email", {
                cartItems: cartItems,
                formData: formData,
                recaptchaToken: recaptchaToken
            });

            console.log("Email sent successfully:", response.data);
            localStorage.removeItem('cart');
            navigate("/checkqweqwe");

        } catch (error) {
            console.error("Error sending email:", error);
        }
    };

    const switchPostCityList = () => {
        setPostCityList(!postCityList);
    };

    const switchPostorPart = (party) => {
        setDeliveryFromPart(party === 'part');
        setDeliveryFromPost(party === 'post');
    };
    const [ohMyGood, setOhMyGood] = useState(false);
    const [postCityList2, setPostCityList2] = useState(false);
    const retrievePostsFromNP = async () => {
        try {
            const data = {
                apiKey: "ab702d5fe11fa5f4995bd5d21caad464",
                modelName: "AddressGeneral",
                calledMethod: "getSettlements",
                methodProperties: {
                    FindByString: formData.city

                }
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.post('https://api.novaposhta.ua/v2.0/json/', data, config);
            setPoll(response.data.data);
            console.log(response.data.data);
            
            if (ohMyGood === false) {
                setPostCityList(true);
            }
            setOhMyGood(false);
            
            

        } catch (error) {
            console.error('Error making request:', error);
        }
    };
    const retrievePostsFromNP2 = async () => {
        try {
            const data = {
                "apiKey": "ab702d5fe11fa5f4995bd5d21caad464",
                "modelName": "AddressGeneral",
                "calledMethod": "getWarehouses",
                "methodProperties": {
                    "CityName": formData.city,
                    "FindByString": formData.department
                }
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.post('https://api.novaposhta.ua/v2.0/json/', data, config);
            setPoll(response.data.data);
            console.log(response.data.data);
            
            if (ohMyGood === false) {
                setPostCityList2(true);
            }
            setOhMyGood(false);
            
            

        } catch (error) {
            console.error('Error making request:', error);
        }
    };
    useEffect(() => {
        if (formData.city) {
            retrievePostsFromNP();
            
        }
    }, [formData.city]);
    useEffect(() => {
        if (formData.department) {
            retrievePostsFromNP2();
            
        }
    }, [formData.department]);
    const [newPostCheck, setNewPostCheck] = useState(false);
    const [selfCheck, setSelfCheck] = useState(false);
    const choiceChangeColor = (option) => {
        return  newPostCheck && option === 'part' ? styles.chooseDeliveryOptionActive :
                selfCheck && option === 'post' ? styles.chooseDeliveryOptionActive :
               styles.chooseDeliveryOption;
    };
    const handleCheckboxChange = () => {
        setFormData((prevState) => ({
            ...prevState,
            selfSerf: !prevState.selfSerf,
        }));
        
    };
    return (
        <div id={styles.wrapMainCon3}>
            <div id={styles.checkoutDetails}>
                <div id={styles.orderList}>
                    <div id={styles.MainHName}><h1>Оформлення замовлення</h1></div>

                    <div id={styles.contactData}>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.formGroup}>
                                <label htmlFor="phone">Телефон</label>
                                <input type="text" placeholder="Телефон" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} required />
                                <label htmlFor="name">Прізвище та ім'я</label>
                                <input type="text" placeholder="Прізвище та ім'я" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
                            </div>
                        </form>
                    </div>

                    <div id={styles.delivery}>
                        <div id={styles.postChoices}>
                            <div id={styles.contForPostChoices}>
                                <div className={choiceChangeColor('part')} onClick={() => { setNewPostCheck(true); setSelfCheck(false) }}>Нова Пошта</div>
                                <div className={choiceChangeColor('post')} onClick={() => { setNewPostCheck(false); setSelfCheck(true) }}>Самовивіз</div>
                            </div>
                            {
                                newPostCheck &&
                                <div id={styles.NewPost}>
                            <div className={styles.PostLogo}><img src={LogoNewPost} alt="Logo" />Нова Пошта</div>
                            <div id={styles.postChoices}>                                
                            </div>
                            {
                                deliveryFromPart &&
                                <form id={styles.NewPostForm}>
                                    <label htmlFor="city">Населений пункт</label>
                                    <input onClick={retrievePostsFromNP} type="text" placeholder="Населений пункт" id="city" name="city" value={formData.city} onChange={handleInputChange} required />
                                    <label htmlFor="department">Відділення</label>
                                    <input onClick={retrievePostsFromNP2} type="text" placeholder="Відділення" id="department" name="department" value={formData.department} onChange={handleInputChange} required />
                                </form>
                            }                            
                            {
                                postCityList &&
                                <div id={styles.postCityList}>
                                    <div id={styles.postCityListIt}>
                                        <div className={styles.TopElement} onClick={() => setPostCityList(false)}><FontAwesomeIcon icon={faArrowLeft} /></div>
                                        <label htmlFor="city">Населений пункт</label>
                                        <input onClick={retrievePostsFromNP} type="text" placeholder="Населений пункт" id="city" name="city" value={formData.city} onChange={handleInputChange} required />
                                        {poll.length > 0 &&
                                            poll.map((item, index) => (
                                                <div className={styles.itemForCityandDepart} key={index} onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        city: item.Description
                                                    }));
                                                    setPostCityList(false);
                                                    setOhMyGood(true);
                                                }}> {item.SettlementTypeDescription} {item.Description} {item.AreaDescription} область  {item.Index1}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            {
                                postCityList2 &&
                                <div id={styles.postCityList}>
                                    <div id={styles.postCityListIt}>
                                        <div className={styles.TopElement} onClick={() => setPostCityList2(false)}><FontAwesomeIcon icon={faArrowLeft} /></div>
                                        <label htmlFor="department">Відділення</label>
                                        <input onClick={retrievePostsFromNP2} type="text" placeholder="Відділення" id="department" name="department" value={formData.department} onChange={handleInputChange} required />
                                        {poll.length > 0 &&
                                            poll.map((item, index) => (
                                                <div className={styles.itemForCityandDepart} key={index}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        department: item.Description
                                                    }));
                                                        setPostCityList2(false);
                                                        setOhMyGood(true);
                                                    }}
                                                > {item.AreaDescription} {item.Description} {item.Index1}</div>
                                            ))
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        {selfCheck && (
                            <div id={styles.selfServe}>
                                <div>
                                    <input
                                        type="checkbox"
                                        checked={formData.selfSerf}
                                        onChange={handleCheckboxChange}
                                    /> Самовивіз зі складу - вулиця Шахтарська, 5, Київ
                                </div>
                            </div>
                        )}
                    </div>
                    <div id={styles.forNotes}>
                        <div className={styles.contactsMName}><h2>Контактні дані</h2></div>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.formGroup}>
                                <label htmlFor="notes">Примітка</label>
                                <textarea height="500px" type="text" placeholder="Адрес доставки, контактні дані" id="notes" name="notes" value={formData.notes} onChange={handleInputChange} required />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div id={styles.checkoutApply}>
                <div id={styles.itemsList}>
                    {
                        cartItems.map((item, index) => (
                            <div key={index} className={styles.productItemCO}>
                                <div className={styles.imageCO}><img src={`http://elektryka.kyiv.ua/${item.image}.jpg`} alt={item.name} /></div>
                                <div className={styles.nameCO}>{item.name}</div>
                                <div className={styles.quantCO}>x {item.quantity}</div>
                                <div className={styles.priceCO}>{item.price} ₴</div>
                            </div>
                        ))
                    }
                </div>
                <div id={styles.MainHName2}><h1>Разом</h1></div>
                <div id={styles.applySum}>{calculateTotal()} ₴</div>
                <button id={styles.buttonApply} onClick={handleSubmit} disabled={!recaptchaReady}>Замовлення підтверджую</button>
            </div>
            {errorM && (
                <div id={styles.ErrorM}>Будь ласка, заповніть обов'язкові поля!</div>
            )}
        </div>
    );
};

export default MainContent3;
