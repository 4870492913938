import React, { useState, useRef, useEffect } from 'react';
import styles from './Header.module.css';
import MyLogo from './poloLogo.png';
import MyLogoSmall from './poloLogoSmall.png';
import DropDown from './DropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faCartShopping, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PurchaseCart from "./PurchaseCart.js";
import SearchComponent from "./SearchElement.js";
import AutoOff from './DropDownContent/AvtoOff.js';



const Header = () => {
    const [DrDo, setDrDo] = useState(false);
    const [pCart, setPCart] = useState(false);
    const buttonRef = useRef(null); 
    const buttonRef2 = useRef(null)   
    const innerElementRef = useRef(null); 
    const innerElementRef2  = useRef(null); // Ref for the inner element inside DropDown
    const [cartItemsHead, setCartItemsHead] = useState([]);

    const tg = (event) => {
        // Prevent the click event from propagating further
        event.stopPropagation();
        
        // Toggle the visibility of the DropDown component
        setDrDo((prevState) => !prevState);
    };
    const forPurchCart = (event) => {
        event.stopPropagation();
        setPCart((prevState)=>!prevState);
    };  
    

    // Run effect every second
    useEffect(() => {
        const interval = setInterval(() => {
            // Your logic to run every second
            console.log('Running every second');
            // You can also check for updates to localStorage here if needed
            const storedCartItems = JSON.parse(localStorage.getItem('cart'));
            if (storedCartItems) {
                setCartItemsHead(storedCartItems);
            }
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);
    const kji= cartItemsHead.length;
    
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target) && 
                innerElementRef.current && !innerElementRef.current.contains(event.target)) {
                setDrDo(false);
            }
    
            if (buttonRef2.current && !buttonRef2.current.contains(event.target) &&
                innerElementRef2.current && !innerElementRef2.current.contains(event.target)) {
                setPCart(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    // State to store the current window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Image sources for different screen sizes
    const smallImage = MyLogoSmall;
    const largeImage = MyLogo;

    // Effect to listen for window resize events and update windowWidth state
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    // Determine which image to display based on window width
    const imageToShow = windowWidth < 600 ? smallImage : largeImage;
    const oopw = () => {
        return DrDo === true ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faList} />;
    };
    return (
        <div id={styles.forHeader}>
            <div className={`${styles.headerDivs}`} id={styles.imageHeader}>
                <Link to="/"><img  src={imageToShow} alt="Logo" /></Link>
            </div>
            
            <div className={styles.headerDivs}>
                {/* Attach the ref to the button */}
                <button id={styles.forButCateg} ref={buttonRef} onClick={tg}>
                    <p className={styles.forDisable}>Категорії</p><p id={styles.jiop} className={styles.headIcons}>{oopw()} </p>                   
                </button>
                
            </div>
            <div className={styles.headerDivs} id={styles.kolad} >
                <SearchComponent />
            </div>
            <div className={styles.headerDivs} id={styles.cabinetField}>
                <Link to="/weekgood" className={`${styles.divscabinetField} ${styles.forDisable}`}>Товар тижня</Link>
                <Link to="/sales" className={`${styles.divscabinetField} ${styles.forDisable}`}>Розпродаж</Link>
                <Link to="/news" className={`${styles.divscabinetField} ${styles.forDisable}`}>Новини</Link>                
                <Link to="/contacts" className={`${styles.divscabinetField} ${styles.forDisable}`}>Контакти</Link>
                <div ref={buttonRef2} className={styles.divscabinetField} onClick={forPurchCart}  id={styles.Pip}><p className={styles.forDisable}>Кошик</p> <p className={styles.headIcons}><FontAwesomeIcon icon={faCartShopping} /></p>{kji > 0 && <p id={styles.quantBucket}>{kji}</p>}</div>                                
            </div>

            {/* Render DropDown conditionally and set ref */}
            {DrDo && (
                <div >
                    <DropDown innerElementRef={innerElementRef} setDrDo={setDrDo}/>  {/* Pass the ref to the DropDown */}
                </div>
            )}
            {pCart && (                
                    <PurchaseCart innerElementRef2={innerElementRef2} forPurchCart={forPurchCart} />                
            )}       
            
                     
        </div>
    );
};

export default Header;
