import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import styles from "./Main.module.css";
import Header from "./Header.js";
import MainContent from "./Maincontent.js";
import Footer from "./Footer.js";



const Main = ({DrDo, setDrDo}) => {        
    
    return(
        <div id={styles.wrapper}>            
            <div id={styles.faq}></div>
            <Header setDrDo={setDrDo} DrDo={DrDo} />
            <MainContent />
            <Footer />            
        </div>
    );
};
export default Main;