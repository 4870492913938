import React from 'react';
import styles from './ContactsPage.module.css';

const News = () => {
  const points = [
    "З 17/06/2024 під склад лягають нові позиції. Гофротруба Sokol чорна важка Standard ПВХ УФ диаметром 40 мм (бухта 25м) і диаметром 50 мм (бухта 15м).",
    "17.06 дорожчає продукція ЗЗЦМ на 3-5 %. ",
    "В понеділок 17/06/2024 приїде нова позиція ТНСІ, аналог ІЕК та Енекст, які випали у нас на складі, та на складах постачальників: 1) Обмеж на дін рейку ТНРСІ метал EW-35М.",
    "З 10.06.2024 починає діяти новий Прайс ЕНЕКСТ - НОВІТНІ ЕЛЕКТРОТЕХНІЧНІ ТЕХНОЛОГІЇ.",
    "В складський асортимент введено перемикачі ТМ Купрум. Також ГАЛ-КАТ провід ПВ-3 10 нгд білий, синій, чорний; ПВ-3 1 білий.",
    "Підвищення цін Білмакс. В зв‘язку зі зростанням цін на матеріали, комплектуючі та енергоресурси ми вимушені збільшити ціни на всю виготовляєму продукцію з 10.06.2024р. на 5%.",
    "Компанія ЕТИ піднімає курс з 30,05 до 44грн., з 01,06 піднімається прайс на корпусні автомати та аксесуари NBS. Інформація стосовно продукції, яка подорожчає у вкладенні.",
    "Кабельні Технології з 27.05.2024 змінює прайс. Деякі позиції стали дорожче, деякі дешевше.",
    "Одесакабель, КАРАТ підвищує ціни з 03.06.2024: мідна група: до +5%, алюмінієва група: до +6%."
  ];

  return (
    <div className={styles.contactsPage}>
      {
        points.map((item, index)=>{
          // Extract date if present at the beginning of the string
          const dateMatch = item.match(/^(\d{2}[./]\d{2}[./]\d{4})/);
          const date = dateMatch ? dateMatch[1] : null;
          const content = date ? item.replace(date, '').trim() : item;

          return(
            <div key={index} className={styles.newsItem}>
              {date && <div className={styles.newsDate}>{date}</div>}
              <div className={styles.newsContent}>{content}</div>
            </div>
          );
        })
      }
    </div>
  );
};

export default News;
