import React from "react";

const images = {
    image1: require("./AVRCuprum.jpg"),
    image2: require("./SvitloDiod.jpg"),
    image3: require("./SmatCharge.jpg"),
    image4: require("./earu.jpg")
};
const images2 = {
    schneider: require("./schneider.jpg")
};
const imagesArray = [images, images2];
export default imagesArray;
