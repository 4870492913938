import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import styles from './EmailSuccessNotification.module.css';

const OrderSuccess = () => {
  return (
    <div className={styles.orderSuccess}>
      <p>Ваше замовлення успішно відправлено!</p>
      <p>Ми з Вами зв'яжемося протягом однієї години.</p>
      <Link to="/" className={styles.returnLink}>Повернутись на головну сторінку</Link>
    </div>
  );
};

export default OrderSuccess;
