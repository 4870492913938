import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = ({setPCart}) => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ETI</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/10А/ETI" className={styles.subMenuItem}>Струм 10A</Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/16А/ETI" className={styles.subMenuItem}>Струм 16A</Link>                
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/25А/ETI" className={styles.subMenuItem}>Струм 25A</Link>      
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/32А/ETI" className={styles.subMenuItem}>Струм 32A</Link>
            </div>            
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Eaton</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/10А/Eaton" className={styles.subMenuItem}>Струм 10A</Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/16А/Eaton" className={styles.subMenuItem}>Струм 16A</Link>                
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/25А/Eaton" className={styles.subMenuItem}>Струм 25A</Link>      
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/32А/Eaton" className={styles.subMenuItem}>Струм 32A</Link>        
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>E. NEXT</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/16А/ENEXT" className={styles.subMenuItem}>Струм 16A</Link>  
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/20А/ENEXT" className={styles.subMenuItem}>Струм 20A</Link>               
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/25А/ENEXT" className={styles.subMenuItem}>Струм 25A</Link>      
            </div>            
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Sсhneider</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/16A/Schneider" className={styles.subMenuItem}>Струм 16A</Link>                
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/25A/Schneider" className={styles.subMenuItem}>Струм 25A</Link>      
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/32A/Schneider" className={styles.subMenuItem}>Струм 32A</Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/40A/Schneider" className={styles.subMenuItem}>Струм 40A</Link>
            </div>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Hager</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/10А/Hager" className={styles.subMenuItem}>Струм 10A</Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/16А/Hager" className={styles.subMenuItem}>Струм 16A</Link>                
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/25А/Hager" className={styles.subMenuItem}>Струм 25A</Link>      
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/32А/Hager" className={styles.subMenuItem}>Струм 32A</Link>
                <Link onClick={()=>setPCart(false)} to="/difautomates/current/40А/Hager" className={styles.subMenuItem}>Струм 40A</Link>
            </div>
        </div>
    );
};

export default AutoOff;