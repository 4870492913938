import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = ({setPCart}) => {
    return (
        <div id={styles.contentHeadDrDo}>
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>ETI</h3></Link>  
                <Link onClick={()=>setPCart(false)} to="/switchers/poles/1P/ETI" className={styles.subMenuItem}>Однополюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/poles/2P/ETI" className={styles.subMenuItem}>Двополюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/poles/3P/ETI" className={styles.subMenuItem}>Трьохполюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/poles/4P/ETI" className={styles.subMenuItem}>Чотирьохполюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/capacity/25A/ETI" className={styles.subMenuItem}>Сила струму 25А</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/capacity/40A/ETI" className={styles.subMenuItem}>Сила струму 40А</Link> 
            </div>                     
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Hager</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/poles/1P/Hager" className={styles.subMenuItem}>Однополюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/poles/2P/Hager" className={styles.subMenuItem}>Двополюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/poles/3P/Hager" className={styles.subMenuItem}>Трьохполюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/poles/4P/Hager" className={styles.subMenuItem}>Чотирьохполюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/capacity/25A/Hager" className={styles.subMenuItem}>Сила струму 16А</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/capacity/25A/Hager" className={styles.subMenuItem}>Сила струму 25А</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/capacity/32A/Hager" className={styles.subMenuItem}>Сила струму 32А</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/capacity/40A/Hager" className={styles.subMenuItem}>Сила струму 40А</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/capacity/63A/Hager" className={styles.subMenuItem}>Сила струму 63А</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/capacity/100A/Hager" className={styles.subMenuItem}>Сила струму 100А</Link>
                <Link onClick={()=>setPCart(false)} to="/switchers/capacity/125A/Hager" className={styles.subMenuItem}>Сила струму 125А</Link>
            </div>
        </div>
    );
};

export default AutoOff;