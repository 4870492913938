import React from "react";
import styles from "./ContentItems.module.css";
import { Link } from "react-router-dom";

const AutoOff = ({setPCart}) => {
    return (
        <div id={styles.contentHeadDrDo}>       
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Eaton</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/poles/2P/Eaton" className={styles.subMenuItem}>Двополюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/poles/4P/Eaton" className={styles.subMenuItem}>Чотирьохполюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/current/25A/Eaton" className={styles.subMenuItem}>Ток 25А</Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/current/40A/Eaton" className={styles.subMenuItem}>Ток 40А</Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/current/63A/Eaton" className={styles.subMenuItem}>Ток 63А</Link>           
            </div>            
            <div className={styles.menuItem}>
                <Link className={styles.subMenuItemHead}><h3>Hager</h3></Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/poles/2P/Hager" className={styles.subMenuItem}>Двополюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/poles/4P/Hager" className={styles.subMenuItem}>Чотирьохполюсний</Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/current/16A/Hager" className={styles.subMenuItem}>Ток 16А</Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/current/25A/Hager" className={styles.subMenuItem}>Ток 25А</Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/current/40A/Hager" className={styles.subMenuItem}>Ток 40А</Link>
                <Link onClick={()=>setPCart(false)} to="/pzv/current/63A/Hager" className={styles.subMenuItem}>Ток 63А</Link>
            </div>
        </div>
    );
};

export default AutoOff;